<template>
  <div class="block">
    <div class="block is-hidden-tablet border-01" style="background-color: #fff; padding: 10px;">
      <PScoreDiagramLegend  :pscore="pscore" :risk="risk" />
    </div>
    <div class="block" style="position: relative; background-color: #fff; padding: 35px;" :class="hideBorder ? '' : 'border-01'">
      <div class="is-hidden-mobile" style="position: absolute; left: 25px; top: 35px;">
        <PScoreDiagramLegend  :pscore="pscore" :risk="risk" />
      </div>
      <div class="has-text-right">
        <label class="label">
          <span class="color-01" style="margin-right:15px;">{{$t('components.PScoreDiagram.risk')}}</span>
          </label>
      </div>
      <div>
        <PScoreGraph :height="290" :pscore="pscore" :risk="risk" />
      </div>
      <div class="columns is-mobile" style="margin-bottom: -10px;">
        <div class="column is-1-mobile is-2-tablet is-2-desktop is-2-widescreen is-2-fullhd">
          <label class="label"><span class="color-01" style="margin-left: 3px;">{{$t('components.PScoreDiagram.low')}}</span></label>
        </div>
        <div class="column is-2">
          <label class="label"><span class="color-01" style="margin-left: 32px;">{{$t('components.PScoreDiagram.intermediate')}}</span></label>
        </div>
        <div class="column has-text-right is-7">
          <label class="label"><span class="color-01">{{$t('components.PScoreDiagram.high')}}</span></label>
        </div>
      </div>
      <div>
        <PScoreBar :height="50"/>
      </div>
      <div class="block">
        <label class="label">
          <span class="color-01" style="margin-left: 3px;">{{$t('components.PScoreDiagram.pscore')}}</span>
          </label>
      </div>
    </div>
  </div>
</template>

<script>
import PScoreGraph from '@/components/PScoreResult/PScoreDiagram/PScoreGraph.vue';
import PScoreBar from '@/components/PScoreResult/PScoreDiagram/PScoreBar.vue';
import PScoreDiagramLegend from '@/components/PScoreResult/PScoreDiagram/PScoreDiagramLegend.vue'

export default {
  name: 'PScoreResult',
  props: [ 'pscore', 'risk', 'hideBorder' ],
  components: {
    PScoreGraph,
    PScoreBar,
    PScoreDiagramLegend
  }
}
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 374px) { 
    .color-01 {
      font-size: .8em;
    }
  }
</style>

