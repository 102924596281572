<template>
  <div>
    <UserInfo :style="{visibility: isCalculatingPScore ? 'hidden' : 'visible'}"/>
    <Navigation :isNavigationHidden="isCalculatingPScore" />

    <div class="main-content pt-5">
      <PScoreCalculator @is-calculating-changed="isCalculatingPScoreChanged" />
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/shared/Navigation.vue';
import UserInfo from '@/components/shared/UserInfo.vue';

import PScoreCalculator from '@/components/PScoreCalculator/PScoreCalculator.vue';

export default {
  name: 'PScoresView',
  components: {
    Navigation,
    UserInfo,
    PScoreCalculator
  },
  data: function () {
    return {
      isCalculatingPScore: false
    };
  },
  methods: {
    isCalculatingPScoreChanged: function(value) {
      this.isCalculatingPScore = value;
    }
  }
}
</script>
