import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

import VueFlashMessage from 'vue-flash-message';
Vue.use(VueFlashMessage, {
  createShortcuts: false,
  messageOptions: {
    timeout: 6000
  }
});
require('../node_modules/vue-flash-message/dist/vue-flash-message.min.css');

import { library } from '../node_modules/@fortawesome/fontawesome-svg-core';
import { 
  faSpinner,
  faAngleDown,
  faAngleUp,  
  faCircle, 
  faChevronRight, 
  faLock } from '../node_modules/@fortawesome/free-solid-svg-icons';

import {
  faFrown,
  faSmile } from "../node_modules/@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from '../node_modules/@fortawesome/vue-fontawesome';
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

library.add([
  faSpinner,
  faAngleDown,
  faAngleUp,  
  faCircle,
  faChevronRight,
  faLock,
  faFrown,
  faSmile
]);

import i18n from './i18n';

import setupInterceptors from './httpInterceptors';
setupInterceptors();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
