<template>
  <a href="javascript:" @click.prevent="clicked">
    <span>{{text}}</span>
    <FontAwesomeIcon :icon="['fa', 'spinner']" pulse style="margin-left: 5px;" v-if="isLoading" />
  </a>
</template>

<script>
export default {
  name: 'LoadingLink',
  props: [ 'text', 'isLoading' ],
  methods: {
    clicked() {
      this.$emit('click');
    }
  }
}
</script>
