<script>
export default {
  stage: process.env.VUE_APP_STAGE,
  apiUrl: process.env.VUE_APP_API_URL,

  secureAppboxUrl: process.env.VUE_APP_SECURE_APPBOX_URL,
  secureAppboxClientId: process.env.VUE_APP_SECURE_APPBOX_CLIENT_ID,

  emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

  refreshTokenUpdateWhenLeftSeconds: 1800, // 30 minutes

  maxPScoreValue: 12,

  USER_FIELDS: [ 'id', 'name', 'firstName', 'lastName', 'email', 'roles', 'touVersion', 'isTouAccepted', 'isPreviousTouAccepted', 'version' ],
  AUTH_FIELDS: [ 'token', 'tokenType', 'tokenExpiration', 'refreshToken', 'refreshTokenExpiration', 'scope' ],

  getTOUFile(version='2', lang='en') {
  //Query params version set to avoid caching after changes
    return `/docs/TOU/Terms_of_Use_PWS_V${version}_${lang}.pdf?version=2`;
  },
  getIFUFile(lang='en') {
  //Query params version set to avoid caching after changes
    return `/docs/P-Score_IFU_v3_221115_${lang}.pdf?version=3`;
  }
}
</script>
