import axios from 'axios';
import { get, pick } from 'lodash';
import moment from 'moment';

import store from './store';
import router from '@/router';

import Settings from '@/Settings';

function setAuthorization(data) {
  store.commit('user/setAuthorization', pick(data, Settings.AUTH_FIELDS));
}

async function refershTokenIfNeeded() {
  const tokenExpiration = get(store, 'state.user.authorization.tokenExpiration', null),
    refreshToken = get(store, 'state.user.authorization.refreshToken', null),
    refreshTokenExpiration = get(store, 'state.user.authorization.refreshTokenExpiration', null),
    token = get(store, 'state.user.authorization.token', '');

  if (tokenExpiration && moment(tokenExpiration).diff(moment(), 'seconds') < Settings.refreshTokenUpdateWhenLeftSeconds
    && refreshToken && moment(refreshTokenExpiration).isAfter(moment())) {

    const response = await axios.post(`${Settings.apiUrl}/token`, { 
      refreshToken 
    }, {
      headers: {
        Authorization: `bearer ${token}`
      }
    });

    setAuthorization(response.data);
  }
}

export default function setup() {
  axios.interceptors.request.use(async config => {
    if (config.url.endsWith('/login') || config.url.endsWith('/token')) {
      return config;
    }

    await refershTokenIfNeeded();

    const token = get(store, 'state.user.authorization.token', null);
    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }

    return config;
  }, err => {
    return Promise.reject(err);
  });

  axios.interceptors.response.use(response => {
    const version = get(response, 'data.version', null);
    if (version) { 
      store.commit('user/setVersion', version);
    }

    return response;
  }, error => {
    if (error.response.status == 401 || error.response.status == 403) {
      router.push('/login');
    }

    return Promise.reject(error);
  });
}
