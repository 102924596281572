<template>
  <div class="block">
    <p><span class="has-text-weight-bold">{{$t('components.PScoreResult.signedBy')}}:</span> {{pscore.metadata.signedByName || pscore.metadata.createdByInitials}} {{getReadableUTCDate(pscore.metadata.createdAt)}}</p>
  </div>
</template>

<script>
import Utils from '@/Utils';

export default {
  name: 'PScoreSignedBy',
  props: [ 'pscore' ],
  methods: {
    getReadableUTCDate(date) {
      return Utils.getReadableUTCDate(date);
    }
  }
}
</script>
