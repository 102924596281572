<template>
  <div>
    <UserInfo />
    <Navigation />
    
    <div class="main-content pt-5">
      <div class="block">
        <h1 class="title pb-5">{{$t('views.home.welcome', { name: this.user.me.name })}}</h1>
      </div>

      <div class="block">
        <div class="pb-3">
          <div class="columns">
            <div class="column">
              <button class="button is-primary" @click="$router.push('/pscores')">{{$t('views.home.calculatePscores')}}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="block">
        <PScores />
      </div>

      <div class="block">
        <Version />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Navigation from '@/components/shared/Navigation.vue';
import UserInfo from '@/components/shared/UserInfo.vue';
import PScores from '@/components/PScores/PScores.vue';
import Version from '@/components/shared/Version.vue';

export default {
  name: 'HomeView',
  components: {
    Navigation,
    UserInfo,
    PScores,
    Version
  },
  computed: {
    ...mapState([
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 374px) { 
    .button {
      min-width: 100%;
    }
  }
</style>
