<template>
  <div class="block">
    <div class="columns is-centered">
      <div class="column">
        <svg width="100%" height="100%" viewBox="0 0 931 142" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Loading P-score</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Calculating_03" transform="translate(-252.000000, -240.000000)" class="pscore-loading-dark">
              <g id="Group-192" transform="translate(252.000000, 240.500000)">
                <!-- 1 and 3 -->

                <transition name="fade" mode="out-in">
                  <path d="M122.5,52.5 C113.939549,52.5 107,59.4396012 107,68.0001174 C107,76.5603988 113.939549,83.5 122.5,83.5 C131.060217,83.5 138,76.5603988 138,68.0001174 C138,59.4396012 131.060217,52.5 122.5,52.5" 
                    id="pscore-loading-1-1" :class="{'pscore-loading-light': this.step === 'step2'}"></path>
                </transition>

                <transition name="fade" mode="out-in">
                  <path d="M123.00013,65.5 C120.790917,65.5 119,67.290859 119,69.5 C119,71.7088817 120.790917,73.5 123.00013,73.5 C125.209342,73.5 127,71.7088817 127,69.5 C127,67.290859 125.209342,65.5 123.00013,65.5"
                    id="pscore-loading-1-2" :class="{'pscore-loading-light': this.step === 'step2'}"></path>
                </transition>

                <transition name="fade" mode="out-in">
                  <path d="M579.500117,53.5 C570.939601,53.5 564,60.4396012 564,69.0001174 C564,77.5603988 570.939601,84.5 579.500117,84.5 C588.060399,84.5 595,77.5603988 595,69.0001174 C595,60.4396012 588.060399,53.5 579.500117,53.5"
                    id="pscore-loading-3-1" :class="{'pscore-loading-light': this.step === 'step2'}"></path>
                </transition>

                <transition name="fade" mode="out-in">
                  <path d="M580.00013,67.5 C577.790658,67.5 576,69.290917 576,71.5001297 C576,73.709083 577.790658,75.5 580.00013,75.5 C582.209342,75.5 584,73.709083 584,71.5001297 C584,69.290917 582.209342,67.5 580.00013,67.5"
                    id="pscore-loading-3-2" :class="{'pscore-loading-light': this.step === 'step2'}"></path>
                </transition>

                <!-- 2 and 4 -->

                <transition name="fade" mode="out-in">
                  <path d="M351.500118,54.5 C342.387298,54.5 335,61.8872985 335,70.9998819 C335,80.1127015 342.387298,87.5 351.500118,87.5 C360.612702,87.5 368,80.1127015 368,70.9998819 C368,61.8872985 360.612702,54.5 351.500118,54.5"
                    id="pscore-loading-2-1" :class="{'pscore-loading-light': this.step === 'step1'}"></path>
                </transition>
              
                <transition name="fade" mode="out-in">
                  <path d="M351,65.5 C348.790859,65.5 347,67.290859 347,69.5 C347,71.7088817 348.790859,73.5 351,73.5 C353.209141,73.5 355,71.7088817 355,69.5 C355,67.290859 353.209141,65.5 351,65.5"
                    id="pscore-loading-2-2" :class="{'pscore-loading-light': this.step === 'step1'}"></path>
                </transition>

                <transition name="fade" mode="out-in">
                  <path d="M808.499882,55.5 C799.387298,55.5 792,62.8872985 792,71.9998819 C792,81.1127015 799.387298,88.5 808.499882,88.5 C817.612702,88.5 825,81.1127015 825,71.9998819 C825,62.8872985 817.612702,55.5 808.499882,55.5"
                    id="pscore-loading-4-1" :class="{'pscore-loading-light': this.step === 'step1'}"></path>
                </transition>

                <transition name="fade" mode="out-in">
                  <path d="M807.99987,67.5 C805.790658,67.5 804,69.290917 804,71.5001297 C804,73.709083 805.790658,75.5 807.99987,75.5 C810.209342,75.5 812,73.709083 812,71.5001297 C812,69.290917 810.209342,67.5 807.99987,67.5"
                    id="pscore-loading-4-2" :class="{'pscore-loading-light': this.step === 'step1'}"></path>
                </transition>

                <path d="M146.5,32.5 C140.14872,32.5 135,37.6487204 135,44 C135,50.3512796 140.14872,55.5 146.5,55.5 C152.85128,55.5 158,50.3512796 158,44 C158,37.6487204 152.85128,32.5 146.5,32.5" id="Fill-3"></path>
                <path d="M173.5,14.5 C167.700937,14.5 163,19.2009371 163,25 C163,30.7990629 167.700937,35.5 173.5,35.5 C179.299063,35.5 184,30.7990629 184,25 C184,19.2009371 179.299063,14.5 173.5,14.5" id="Fill-5"></path>
                <path d="M204.5,4.5 C199.805697,4.5 196,8.30551329 196,13.0001186 C196,17.6944867 199.805697,21.5 204.5,21.5 C209.194303,21.5 213,17.6944867 213,13.0001186 C213,8.30551329 209.194303,4.5 204.5,4.5" id="Fill-7"></path>
                <path d="M237.5,1.5 C233.357905,1.5 230,4.85790513 230,9 C230,13.1420949 233.357905,16.5 237.5,16.5 C241.642095,16.5 245,13.1420949 245,9 C245,4.85790513 241.642095,1.5 237.5,1.5" id="Fill-9"></path>
                <path d="M271,6.5 C267.13396,6.5 264,9.63396005 264,13.5 C264,17.36604 267.13396,20.5 271,20.5 C274.86604,20.5 278,17.36604 278,13.5 C278,9.63396005 274.86604,6.5 271,6.5" id="Fill-11"></path>
                <path d="M301,19.5 C297.133949,19.5 294,22.6341889 294,26.5 C294,30.3660507 297.133949,33.5 301,33.5 C304.866051,33.5 308,30.3660507 308,26.5 C308,22.6341889 304.866051,19.5 301,19.5" id="Fill-13"></path>
                <path d="M328,41.5 C325.238451,41.5 323,43.738682 323,46.5 C323,49.261318 325.238451,51.5 328,51.5 C330.761318,51.5 333,49.261318 333,46.5 C333,43.738682 330.761318,41.5 328,41.5" id="Fill-15"></path>
                <path d="M99,104.5 C105.627362,104.5 111,99.1273624 111,92.5 C111,85.8726376 105.627362,80.5 99,80.5 C92.3726376,80.5 87,85.8726376 87,92.5 C87,99.1273624 92.3726376,104.5 99,104.5" id="Fill-19"></path>
                <path d="M71.499882,121.5 C77.2989267,121.5 82,116.79898 82,111 C82,105.20102 77.2989267,100.5 71.499882,100.5 C65.7008373,100.5 61,105.20102 61,111 C61,116.79898 65.7008373,121.5 71.499882,121.5" id="Fill-21"></path>
                <path d="M40.5,131.5 C45.1944587,131.5 49,127.694459 49,123 C49,118.305771 45.1944587,114.5 40.5,114.5 C35.8057712,114.5 32,118.305771 32,123 C32,127.694459 35.8057712,131.5 40.5,131.5" id="Fill-23"></path>
                <path d="M7.5,134.5 C11.6421683,134.5 15,131.141987 15,127.000117 C15,122.858013 11.6421683,119.5 7.5,119.5 C3.35783166,119.5 0,122.858013 0,127.000117 C0,131.141987 3.35783166,134.5 7.5,134.5" id="Fill-25"></path>
                <path d="M464.499885,138.5 C469.194266,138.5 473,134.694266 473,130.000115 C473,125.305504 469.194266,121.5 464.499885,121.5 C459.805504,121.5 456,125.305504 456,130.000115 C456,134.694266 459.805504,138.5 464.499885,138.5" id="Fill-27"></path>
                <path d="M431.5,132.5 C435.641989,132.5 439,129.142222 439,125 C439,120.857778 435.641989,117.5 431.5,117.5 C427.358011,117.5 424,120.857778 424,125 C424,129.142222 427.358011,132.5 431.5,132.5" id="Fill-29"></path>
                <path d="M401,118.5 C404.866051,118.5 408,115.365997 408,111.49988 C408,107.634003 404.866051,104.5 401,104.5 C397.133949,104.5 394,107.634003 394,111.49988 C394,115.365997 397.133949,118.5 401,118.5" id="Fill-31"></path>
                <path d="M374,97.5 C376.761318,97.5 379,95.261318 379,92.5 C379,89.7384512 376.761318,87.5 374,87.5 C371.238682,87.5 369,89.7384512 369,92.5 C369,95.261318 371.238682,97.5 374,97.5" id="Fill-33"></path>
                <path d="M8.5,0.5 C3.80555556,0.5 0,4.30550417 0,8.99988522 C0,13.6942663 3.80555556,17.5 8.5,17.5 C13.1944444,17.5 17,13.6942663 17,8.99988522 C17,4.30550417 13.1944444,0.5 8.5,0.5" id="Fill-35"></path>
                <path d="M42.5,6.5 C38.3580107,6.5 35,9.85777764 35,14 C35,18.1422224 38.3580107,21.5 42.5,21.5 C46.6422224,21.5 50,18.1422224 50,14 C50,9.85777764 46.6422224,6.5 42.5,6.5" id="Fill-37"></path>
                <path d="M73,19.5 C69.1339493,19.5 66,22.6341889 66,26.5 C66,30.3660507 69.1339493,33.5 73,33.5 C76.8660507,33.5 80,30.3660507 80,26.5 C80,22.6341889 76.8660507,19.5 73,19.5" id="Fill-39"></path>
                <path d="M100,41.5 C97.2384512,41.5 95,43.738682 95,46.5 C95,49.261318 97.2384512,51.5 100,51.5 C102.761318,51.5 105,49.261318 105,46.5 C105,43.738682 102.761318,41.5 100,41.5" id="Fill-41"></path>
                <path d="M374.5,35.5 C368.14874,35.5 363,40.6487932 363,47.0001187 C363,53.3512068 368.14874,58.5 374.5,58.5 C380.85126,58.5 386,53.3512068 386,47.0001187 C386,40.6487932 380.85126,35.5 374.5,35.5" id="Fill-47"></path>
                <path d="M403,16.5 C396.924883,16.5 392,21.4249365 392,27.5001189 C392,33.5753014 396.924883,38.5 403,38.5 C409.075117,38.5 414,33.5753014 414,27.5001189 C414,21.4249365 409.075117,16.5 403,16.5" id="Fill-49"></path>
                <path d="M433.5,6.5 C428.80575,6.5 425,10.3055674 425,15.0001173 C425,19.6944326 428.80575,23.5 433.5,23.5 C438.19425,23.5 442,19.6944326 442,15.0001173 C442,10.3055674 438.19425,6.5 433.5,6.5" id="Fill-51"></path>
                <path d="M466,4.5 C462.134098,4.5 459,7.63409823 459,11.5 C459,15.3661319 462.134098,18.5 466,18.5 C469.866132,18.5 473,15.3661319 473,11.5 C473,7.63409823 469.866132,4.5 466,4.5" id="Fill-53"></path>
                <path d="M328.499881,106.5 C334.851291,106.5 340,101.351345 340,95 C340,88.6486554 334.851291,83.5 328.499881,83.5 C322.148709,83.5 317,88.6486554 317,95 C317,101.351345 322.148709,106.5 328.499881,106.5" id="Fill-55"></path>
                <path d="M300,123.5 C305.52295,123.5 310,119.023131 310,113.499883 C310,107.977102 305.52295,103.5 300,103.5 C294.477284,103.5 290,107.977102 290,113.499883 C290,119.023131 294.477284,123.5 300,123.5" id="Fill-57"></path>
                <path d="M269.5,134.5 C274.194494,134.5 278,130.694494 278,126 C278,121.305506 274.194494,117.5 269.5,117.5 C264.805742,117.5 261,121.305506 261,126 C261,130.694494 264.805742,134.5 269.5,134.5" id="Fill-59"></path>
                <path d="M236.5,137.5 C240.642149,137.5 244,134.142098 244,130.000114 C244,125.857674 240.642149,122.5 236.5,122.5 C232.357851,122.5 229,125.857674 229,130.000114 C229,134.142098 232.357851,137.5 236.5,137.5" id="Fill-61"></path>
                <path d="M203.5,132.5 C207.641989,132.5 211,129.142222 211,125 C211,120.857778 207.641989,117.5 203.5,117.5 C199.358011,117.5 196,120.857778 196,125 C196,129.142222 199.358011,132.5 203.5,132.5" id="Fill-63"></path>
                <path d="M173,118.5 C176.866051,118.5 180,115.365997 180,111.49988 C180,107.634003 176.866051,104.5 173,104.5 C169.133949,104.5 166,107.634003 166,111.49988 C166,115.365997 169.133949,118.5 173,118.5" id="Fill-65"></path>
                <path d="M146,97.5 C148.761318,97.5 151,95.261318 151,92.5 C151,89.7384512 148.761318,87.5 146,87.5 C143.238682,87.5 141,89.7384512 141,92.5 C141,95.261318 143.238682,97.5 146,97.5" id="Fill-67"></path>
                <path d="M468,12.5401084 C467.390349,22.364555 467.017652,32.1915968 466.727456,42.0198183 C466.561253,46.9335751 466.477792,51.8482757 466.358116,56.7625044 L466.122362,71.506842 L466.014198,86.2523593 L466.016596,93.6257078 L466.018755,97.312618 L466.048014,100.999528 C466.113488,110.831289 466.266261,120.664229 466.661502,130.5 L463,130.459656 C463.618285,120.635209 463.99434,110.808167 464.282617,100.980182 L464.395817,97.2946871 L464.481437,93.6089566 L464.651238,86.2374956 L464.877638,71.493158 L464.976688,56.7474047 C464.968294,51.8317605 464.996355,46.9163521 464.941673,42.0002359 C464.87452,32.1682395 464.725106,22.3352994 464.338498,12.5 L468,12.5401084 Z" id="Fill-69"></path>
                <path d="M435,14.5401612 C434.517094,23.6993026 434.21445,32.8605828 433.975818,42.0223382 C433.839211,46.6030971 433.769069,51.1848066 433.666797,55.7658031 L433.460048,69.5102185 L433.352381,83.2558222 C433.350419,87.8380069 433.315348,92.419954 433.347476,97.0023764 C433.376662,106.167221 433.470103,115.332779 433.744298,124.5 L430,124.459839 C430.483396,115.30046 430.786285,106.139655 431.024673,96.9774241 C431.161525,92.3969029 431.231177,87.8154311 431.333693,83.2341969 L431.540197,69.4897815 L431.647128,55.7441778 C431.649581,51.1619931 431.684161,46.580046 431.652033,41.9976236 C431.622848,32.8327788 431.529651,23.6674588 431.255702,14.5 L435,14.5401612 Z" id="Fill-71"></path>
                <path d="M404,27.5361223 C403.478785,34.5292166 403.165206,41.5241996 402.910307,48.5194187 L402.731347,53.7661281 L402.59726,59.0133098 C402.493176,62.5112735 402.456269,66.0097094 402.396261,69.5079091 C402.282885,76.5047809 402.275185,83.5023609 402.350327,90.5006493 C402.444586,97.4991737 402.616377,104.498406 403.053954,111.5 L399,111.464114 C399.595295,104.471728 399.924805,97.4769809 400.176783,90.4812896 C400.409378,83.4855983 400.559397,76.4894348 400.603739,69.4920909 C400.62259,65.9934189 400.664543,62.494983 400.639318,58.9958388 L400.623652,53.7474767 L400.562848,48.4986425 C400.465668,41.500118 400.310074,34.5008854 399.946312,27.5 L404,27.5361223 Z" id="Fill-73"></path>
                <path d="M377,47.5328442 C376.514462,51.2759143 376.251628,55.0210808 376.022822,58.7667133 C375.808566,62.5123457 375.690525,66.2586769 375.629745,70.005707 C375.568026,73.75297 375.582106,77.5004659 375.717747,81.2493594 C375.86254,84.998253 376.082194,88.7478453 376.582752,92.5 L373,92.4671558 C373.570021,88.7247845 373.859138,84.9798509 374.073629,81.2342185 C374.278733,77.4885861 374.362276,73.741789 374.370255,69.994293 C374.378938,66.2467971 374.330595,62.4988353 374.185802,58.7499418 C374.026459,55.0008153 373.833089,51.2514559 373.417248,47.5 L377,47.5328442 Z" id="Fill-75"></path>
                <path d="M240,9.53946371 C239.48796,19.6152351 239.165388,29.6931333 238.902772,39.7715042 C238.631044,49.849875 238.46652,59.9291911 238.334612,70.0089798 C238.262903,75.048756 238.246834,80.0892411 238.200307,85.1294899 C238.191433,90.169975 238.16697,95.2102238 238.193112,100.251182 C238.226209,110.332624 238.340129,120.415249 238.661502,130.5 L235,130.460536 C235.544657,120.385237 235.881619,110.307576 236.137759,100.228968 C236.275182,95.1899012 236.362481,90.1503613 236.465129,85.1108214 C236.530123,80.0708089 236.625815,75.031269 236.665388,69.9910202 C236.756523,59.9107589 236.815042,49.8302613 236.766356,39.7483458 C236.727024,29.6666667 236.627254,19.5845149 236.338498,9.5 L240,9.53946371 Z" id="Fill-77"></path>
                <path d="M273,13.5389233 C272.388826,22.8637643 272.019718,32.1912001 271.739883,41.5193437 L271.630009,45.017486 L271.547604,48.5158642 L271.383038,55.5123847 L271.170648,69.507077 L271.083337,83.5029487 C271.093148,88.1685539 271.070829,92.8339231 271.127728,97.5002359 C271.20155,106.831918 271.355324,116.164779 271.744298,125.5 L268,125.461077 C268.598175,116.136236 268.961397,106.8088 269.24442,97.4806563 C269.405798,92.8167025 269.488203,88.151805 269.602737,83.4876153 L269.829352,69.4931589 L269.930887,55.4975231 L269.923284,48.4988795 L269.91936,44.9997936 L269.887968,41.5002359 C269.817089,32.168318 269.657674,22.8354564 269.255702,13.5 L273,13.5389233 Z" id="Fill-79"></path>
                <path d="M303,26.535019 C302.424057,33.7778023 302.085502,41.022715 301.832448,48.268101 C301.681625,51.8906757 301.622411,55.5141969 301.515666,59.1370082 L301.336697,70.0070984 L301.285183,80.8783718 C301.309347,84.5023661 301.295805,88.1261239 301.36564,91.7505915 C301.462294,98.9992902 301.640733,106.248462 302.053903,113.5 L298,113.464981 C298.570632,106.222198 298.906798,98.977285 299.161179,91.7316623 C299.310143,88.1090876 299.375199,84.4858031 299.478492,80.8627552 L299.663303,69.9926649 L299.720659,59.1216282 C299.693043,55.4973972 299.712693,51.8736395 299.640733,48.2491718 C299.545406,41.0007098 299.364578,33.7513014 298.946097,26.5 L303,26.535019 Z" id="Fill-81"></path>
                <path d="M330,46.5308959 C329.503901,50.5244202 329.238721,54.519817 329.03127,58.515682 C328.836022,62.511781 328.725726,66.5085822 328.695923,70.5060855 C328.663772,74.5033549 328.704371,78.5013263 328.83039,82.5002341 C328.971429,86.4991418 329.162922,90.4985176 329.582752,94.5 L326,94.4688701 C326.489528,90.4753457 326.750484,86.4799489 326.960986,82.4840839 C327.156468,78.4879849 327.266764,74.4911838 327.304077,70.4939145 C327.343737,66.4966451 327.302904,62.4984396 327.177354,58.4997659 C327.039366,54.5008582 326.843884,50.5012483 326.417248,46.5 L330,46.5308959 Z" id="Fill-83"></path>
                <path d="M206,12.5389233 C205.407466,21.8640002 205.045715,31.191436 204.756806,40.5195796 C204.45686,49.8477232 204.287389,59.1770462 204.157649,68.5068411 C204.084809,73.1717385 204.081866,77.8373436 204.040418,82.5024769 L204.045814,89.5008846 C204.050719,91.8336872 204.046304,94.1664897 204.078678,96.4995282 C204.150047,105.831682 204.320498,115.164544 204.744298,124.5 L201,124.461077 C201.633247,115.136708 202.0129,105.809272 202.293717,96.4811281 C202.378084,94.1492691 202.426154,91.8169384 202.483543,89.4846076 L202.645657,82.4878512 C202.708932,77.8231897 202.810467,73.1585282 202.842351,68.4931589 C202.922058,59.1628923 202.961789,49.8321538 202.871291,40.5 C202.791583,31.1680821 202.639035,21.8352205 202.255702,12.5 L206,12.5389233 Z" id="Fill-85"></path>
                <path d="M175,24.535019 C174.419278,31.7778023 174.074084,39.0224784 173.794211,46.2678644 L173.597451,51.7019631 L173.453532,57.136535 C173.341211,60.7595829 173.300584,64.3831041 173.239511,68.0063886 C173.101434,75.2529577 173.123739,82.5007098 173.214817,89.7494085 L173.322623,95.1861099 L173.489113,100.623285 C173.631174,104.248699 173.778279,107.873639 174.053903,111.5 L170,111.464981 C170.354222,107.844063 170.580191,104.222198 170.801115,100.600333 L171.086033,95.1669441 L171.312002,89.7328454 C171.560807,82.4874594 171.740839,75.2411269 171.760223,67.9936114 C171.778279,64.3698536 171.816251,60.7463325 171.782793,57.1221015 L171.757302,51.6861099 L171.678704,46.2496451 C171.556559,39.0007098 171.369092,31.751538 170.946097,24.5 L175,24.535019 Z" id="Fill-87"></path>
                <path d="M148,44.5311299 C147.732708,46.527658 147.547081,48.5248883 147.375535,50.5223527 C147.203755,52.519583 147.066002,54.5172814 146.955471,56.5152139 C146.725491,60.5108448 146.596656,64.5074119 146.530478,68.5046812 C146.460546,72.5017164 146.477677,76.4996879 146.623409,80.4985956 C146.786037,84.4977374 147.024699,88.4975814 147.582752,92.5 L144,92.4691041 C144.627515,88.4767501 144.935641,84.4818214 145.167732,80.4859564 C145.383397,76.4900915 145.469757,72.4932903 145.469522,68.4955529 C145.472807,64.4978154 145.413435,60.4996099 145.253153,56.5004681 C145.177354,54.5008972 145.074333,52.5010923 144.937284,50.5010533 C144.800469,48.5010143 144.649575,46.5009752 144.417248,44.5 L148,44.5311299 Z" id="Fill-89"></path>
                <path d="M102,46.5328442 C101.430214,50.2752155 101.159636,54.020382 100.953124,57.7660144 C100.761631,61.5118798 100.695219,65.2589099 100.695453,69.0064058 C100.691464,72.7539017 100.748959,76.5020964 100.881079,80.250757 C101.029627,83.9996506 101.20704,87.74901 101.582752,91.5 L98,91.4671558 C98.4451745,87.7238528 98.6920505,83.9784533 98.9102963,80.2328209 C99.1118803,76.4871884 99.2388384,72.7406243 99.3045468,68.9935942 C99.3742446,65.2467971 99.3775301,61.4990683 99.2555001,57.7504076 C99.1184512,54.0015141 98.9175711,50.2521547 98.4172485,46.5 L102,46.5328442 Z" id="Fill-91"></path>
                <path d="M74,26.5361222 C73.4697292,33.5291968 73.1561338,40.5241602 72.925385,47.5195957 C72.7960701,51.0173135 72.7302177,54.5155034 72.6362188,58.0136934 C72.5857674,61.5121194 72.5092937,65.0100733 72.480616,68.5087354 C72.4232608,75.5058236 72.3977695,82.503384 72.4869888,89.5018887 C72.5658524,96.5001574 72.7095061,103.499134 73.0539033,110.5 L69,110.463878 C69.5021243,103.470567 69.803505,96.4756037 70.0398301,89.4799321 C70.2867764,82.4844966 70.4192777,75.4881167 70.519384,68.4912646 C70.5693043,64.9928385 70.5716941,61.4939403 70.6003717,57.9955142 C70.5849708,54.496616 70.5979819,50.9977178 70.5475305,47.4985834 C70.4745088,40.5000787 70.3183749,33.5011018 69.9460967,26.5 L74,26.5361222 Z" id="Fill-93"></path>
                <path d="M44,13.5401612 C43.4047677,22.6983521 43.0430176,31.8586817 42.7624467,41.0201994 C42.6010693,45.6007207 42.5211164,50.1819549 42.4060921,54.763189 L42.1814392,68.5073669 L42.0823564,82.2529705 L42.088733,89.1262476 L42.0926571,92.563005 L42.1238044,96 C42.1929661,105.16532 42.3491931,114.331353 42.7442978,123.5 L39,123.459839 C39.6043067,114.301886 39.9697356,105.141318 40.2483445,95.9800382 L40.3579732,92.544469 L40.4403787,89.1086622 L40.6037181,82.2370486 L40.8185608,68.4928708 L40.9078334,54.7470295 C40.8972875,50.1646071 40.9220582,45.58266 40.8654044,40.9997624 C40.7940354,31.83468 40.6417325,22.668647 40.2557022,13.5 L44,13.5401612 Z" id="Fill-95"></path>
                <path d="M11,9.54010845 C10.4836187,19.3657347 10.1593515,29.1932483 9.89960186,39.0217057 C9.62905934,48.8499272 9.47004365,58.6793284 9.33837003,68.5089654 L9.21149326,83.2544827 C9.21317216,88.170127 9.17455749,93.0852994 9.20909483,98.0011797 C9.24195328,107.832704 9.35060201,117.665172 9.6614381,127.5 L6,127.459656 C6.53412961,117.634265 6.86583201,107.806752 7.12150429,97.9782943 C7.26780832,93.0643015 7.34072049,88.1496009 7.45392622,83.2351363 L7.66162997,68.4907986 C7.75301003,58.6604538 7.81704802,48.829873 7.76979901,38.9983485 C7.73286324,29.166824 7.63188948,19.3343557 7.33832206,9.5 L11,9.54010845 Z" id="Fill-97"></path>
                <path d="M602.5,33.5 C596.14872,33.5 591,38.6487204 591,45 C591,51.3510381 596.14872,56.5 602.5,56.5 C608.85128,56.5 614,51.3510381 614,45 C614,38.6487204 608.85128,33.5 602.5,33.5" id="Fill-101"></path>
                <path d="M630.499884,15.5 C624.700885,15.5 620,20.2009371 620,26 C620,31.7990629 624.700885,36.5 630.499884,36.5 C636.298882,36.5 641,31.7990629 641,26 C641,20.2009371 636.298882,15.5 630.499884,15.5" id="Fill-103"></path>
                <path d="M661.500119,5.5 C656.805751,5.5 653,9.30551329 653,13.9998814 C653,18.6944867 656.805751,22.5 661.500119,22.5 C666.194487,22.5 670,18.6944867 670,13.9998814 C670,9.30551329 666.194487,5.5 661.500119,5.5" id="Fill-105"></path>
                <path d="M694.5,2.5 C690.357905,2.5 687,5.85795691 687,10.0001157 C687,14.1422744 690.357905,17.5 694.5,17.5 C698.642095,17.5 702,14.1422744 702,10.0001157 C702,5.85795691 698.642095,2.5 694.5,2.5" id="Fill-107"></path>
                <path d="M727.999882,7.5 C724.134013,7.5 721,10.63396 721,14.5 C721,18.36604 724.134013,21.5 727.999882,21.5 C731.865987,21.5 735,18.36604 735,14.5 C735,10.63396 731.865987,7.5 727.999882,7.5" id="Fill-109"></path>
                <path d="M757.99988,20.5 C754.133896,20.5 751,23.6339493 751,27.5 C751,31.3660507 754.133896,34.5 757.99988,34.5 C761.866104,34.5 765,31.3660507 765,27.5 C765,23.6339493 761.866104,20.5 757.99988,20.5" id="Fill-111"></path>
                <path d="M786,42.5 C783.238682,42.5 781,44.7384512 781,47.5 C781,50.261318 783.238682,52.5 786,52.5 C788.761549,52.5 791,50.261318 791,47.5 C791,44.7384512 788.761549,42.5 786,42.5" id="Fill-113"></path>
                <path d="M557.000119,105.5 C563.627416,105.5 569,100.127362 569,93.5 C569,86.8726376 563.627416,81.5 557.000119,81.5 C550.372822,81.5 545,86.8726376 545,93.5 C545,100.127362 550.372822,105.5 557.000119,105.5" id="Fill-117"></path>
                <path d="M528.500118,122.5 C534.298927,122.5 539,117.79898 539,112.000236 C539,106.20102 534.298927,101.5 528.500118,101.5 C522.701073,101.5 518,106.20102 518,112.000236 C518,117.79898 522.701073,122.5 528.500118,122.5" id="Fill-119"></path>
                <path d="M498.499885,132.5 C503.19428,132.5 507,128.694407 507,123.999885 C507,119.305593 503.19428,115.5 498.499885,115.5 C493.80549,115.5 490,119.305593 490,123.999885 C490,128.694407 493.80549,132.5 498.499885,132.5" id="Fill-121"></path>
                <path d="M922,140.5 C926.970588,140.5 931,136.470643 931,131.500122 C931,126.529357 926.970588,122.5 922,122.5 C917.029412,122.5 913,126.529357 913,131.500122 C913,136.470643 917.029412,140.5 922,140.5" id="Fill-123"></path>
                <path d="M889.500117,133.5 C893.642041,133.5 897,130.14217 897,125.999883 C897,121.85783 893.642041,118.5 889.500117,118.5 C885.357959,118.5 882,121.85783 882,125.999883 C882,130.14217 885.357959,133.5 889.500117,133.5" id="Fill-126"></path>
                <path d="M858.00012,119.5 C861.866104,119.5 865,116.365811 865,112.49976 C865,108.633949 861.866104,105.5 858.00012,105.5 C854.133896,105.5 851,108.633949 851,112.49976 C851,116.365811 854.133896,119.5 858.00012,119.5" id="Fill-128"></path>
                <path d="M831,98.5 C833.761318,98.5 836,96.2615488 836,93.5002307 C836,90.738682 833.761318,88.5 831,88.5 C828.238451,88.5 826,90.738682 826,93.5002307 C826,96.2615488 828.238451,98.5 831,98.5" id="Fill-130"></path>
                <path d="M499.499883,7.5 C495.357959,7.5 492,10.8577776 492,15 C492,19.1422224 495.357959,22.5 499.499883,22.5 C503.642041,22.5 507,19.1422224 507,15 C507,10.8577776 503.642041,7.5 499.499883,7.5" id="Fill-132"></path>
                <path d="M529.99988,20.5 C526.133896,20.5 523,23.6339493 523,27.5 C523,31.3660507 526.133896,34.5 529.99988,34.5 C533.866104,34.5 537,31.3660507 537,27.5 C537,23.6339493 533.866104,20.5 529.99988,20.5" id="Fill-134"></path>
                <path d="M556.999885,42.5 C554.23863,42.5 552,44.7384512 552,47.5 C552,50.261318 554.23863,52.5 556.999885,52.5 C559.76137,52.5 562,50.261318 562,47.5 C562,44.7384512 559.76137,42.5 556.999885,42.5" id="Fill-136"></path>
                <path d="M831.499881,36.5 C825.148556,36.5 820,41.6485559 820,48.0001187 C820,54.3512068 825.148556,59.5 831.499881,59.5 C837.851207,59.5 843,54.3512068 843,48.0001187 C843,41.6485559 837.851207,36.5 831.499881,36.5" id="Fill-142"></path>
                <path d="M858.999881,18.5 C852.924699,18.5 848,23.4249365 848,29.5001189 C848,35.5753014 852.924699,40.5 858.999881,40.5 C865.075064,40.5 870,35.5753014 870,29.5001189 C870,23.4249365 865.075064,18.5 858.999881,18.5" id="Fill-144"></path>
                <path d="M890.5,8.5 C885.805515,8.5 882,12.3055674 882,17.0001173 C882,21.6944326 885.805515,25.5 890.5,25.5 C895.19425,25.5 899,21.6944326 899,17.0001173 C899,12.3055674 895.19425,8.5 890.5,8.5" id="Fill-146"></path>
                <path d="M923.999885,6.5 C920.134047,6.5 917,9.63409823 917,13.5 C917,17.3661319 920.134047,20.5 923.999885,20.5 C927.865953,20.5 931,17.3661319 931,13.5 C931,9.63409823 927.865953,6.5 923.999885,6.5" id="Fill-148"></path>
                <path d="M784.5,107.5 C790.851238,107.5 796,102.351291 796,96.0001193 C796,89.6487088 790.851238,84.5 784.5,84.5 C778.148524,84.5 773,89.6487088 773,96.0001193 C773,102.351291 778.148524,107.5 784.5,107.5" id="Fill-150"></path>
                <path d="M758,124.5 C763.522716,124.5 768,120.022898 768,114.499883 C768,108.976869 763.522716,104.5 758,104.5 C752.47705,104.5 748,108.976869 748,114.499883 C748,120.022898 752.47705,124.5 758,124.5" id="Fill-152"></path>
                <path d="M726.5,135.5 C731.194494,135.5 735,131.694311 735,127.000118 C735,122.305689 731.194494,118.5 726.5,118.5 C721.805742,118.5 718,122.305689 718,127.000118 C718,131.694311 721.805742,135.5 726.5,135.5" id="Fill-154"></path>
                <path d="M693.5,138.5 C697.64192,138.5 701,135.142149 701,131 C701,126.857623 697.64192,123.5 693.5,123.5 C689.357851,123.5 686,126.857623 686,131 C686,135.142149 689.357851,138.5 693.5,138.5" id="Fill-156"></path>
                <path d="M660.500117,133.5 C664.642041,133.5 668,130.14217 668,125.999883 C668,121.85783 664.642041,118.5 660.500117,118.5 C656.358192,118.5 653,121.85783 653,125.999883 C653,130.14217 656.358192,133.5 660.500117,133.5" id="Fill-158"></path>
                <path d="M630.00012,119.5 C633.866104,119.5 637,116.365811 637,112.49976 C637,108.633949 633.866104,105.5 630.00012,105.5 C626.133896,105.5 623,108.633949 623,112.49976 C623,116.365811 626.133896,119.5 630.00012,119.5" id="Fill-160"></path>
                <path d="M603,98.5 C605.761318,98.5 608,96.2615488 608,93.5002307 C608,90.738682 605.761318,88.5 603,88.5 C600.238451,88.5 598,90.738682 598,93.5002307 C598,96.2615488 600.238451,98.5 603,98.5" id="Fill-162"></path>
                <path d="M925,12.5401085 C924.390349,22.3645747 924.017891,32.1916362 923.727696,42.0198773 C923.561253,46.933644 923.477792,51.8481184 923.358116,56.7625929 L923.122362,71.5067241 L923.014198,86.2525068 L923.016596,93.62587 L923.018755,97.3125516 L923.048254,100.999705 C923.113488,110.831721 923.266261,120.664681 923.661502,130.5 L920,130.459891 C920.618285,120.635425 920.99458,110.808364 921.282617,100.980359 L921.395817,97.2948567 L921.481437,93.6091188 L921.651238,86.2374071 L921.877638,71.4932759 L921.976688,56.7474932 C921.968294,51.8318391 921.996594,46.9164209 921.941673,42.0002949 C921.87452,32.1682789 921.725345,22.3353191 921.338498,12.5 L925,12.5401085 Z" id="Fill-164"></path>
                <path d="M892,16.5401612 C891.516849,25.6993026 891.21445,34.8605828 890.975818,44.0225759 C890.839211,48.6033347 890.769069,53.1848066 890.666797,57.7658031 L890.460048,71.5104562 L890.352381,85.2560598 C890.350419,89.8380069 890.315348,94.419954 890.347722,99.0023764 C890.376662,108.167459 890.470349,117.332779 890.744298,126.5 L887,126.459839 C887.483642,117.300697 887.786285,108.139655 888.024673,98.9776618 C888.16177,94.3969029 888.231177,89.8154311 888.333693,85.2341969 L888.540197,71.4897815 L888.647128,57.7441778 C888.649581,53.1619931 888.684161,48.5802836 888.652278,43.9976236 C888.622603,34.8330165 888.529651,25.6674588 888.255702,16.5 L892,16.5401612 Z" id="Fill-166"></path>
                <path d="M862,28.5361222 C861.478492,35.5291968 861.165162,42.5241602 860.91025,49.5193596 L860.73128,54.7660543 L860.597451,60.0132212 C860.493096,63.5111751 860.456452,67.0096011 860.396442,70.5077911 C860.282793,77.5046432 860.275358,84.5022035 860.350239,91.5004722 C860.444503,98.4989769 860.616304,105.49819 861.053903,112.5 L857,112.463642 C857.595061,105.471275 857.924588,98.4765481 858.17658,91.4811126 C858.409453,84.4854409 858.55948,77.4892971 858.603558,70.4919728 C858.622411,66.9933107 858.664365,63.4948847 858.63914,59.9957503 L858.623473,54.747403 L858.562666,49.4985834 C858.465481,42.5000787 858.309878,35.5008657 857.946097,28.5 L862,28.5361222 Z" id="Fill-168"></path>
                <path d="M833,47.5328442 C832.514696,51.2759143 832.251628,55.0210808 832.022822,58.7667133 C831.808566,62.5123457 831.690525,66.2586769 831.62951,70.005707 C831.568026,73.752737 831.582106,77.5004659 831.717747,81.2493594 C831.86254,84.998253 832.082194,88.7478453 832.582752,92.5 L829,92.4671558 C829.570255,88.7247845 829.859138,84.9798509 830.073629,81.2342185 C830.278733,77.4885861 830.362276,73.741789 830.370255,69.994293 C830.378938,66.2467971 830.330595,62.4988353 830.185802,58.7497088 C830.026459,55.0008153 829.833089,51.2514559 829.417248,47.5 L833,47.5328442 Z" id="Fill-170"></path>
                <path d="M696,9.53946371 C695.48796,19.6152351 695.165627,29.692897 694.903012,39.7715042 C694.631044,49.849875 694.46652,59.9291911 694.334852,70.0089798 C694.262903,75.048756 694.246834,80.0892411 694.200307,85.1292536 C694.191433,90.169975 694.16697,95.2102238 694.193352,100.251182 C694.226209,110.332861 694.339889,120.415249 694.661502,130.5 L691,130.460773 C691.544657,120.385237 691.881619,110.307576 692.137759,100.229205 C692.275422,95.1899012 692.362481,90.1503613 692.465368,85.1108214 C692.530123,80.0708089 692.625815,75.031269 692.665388,69.9910202 C692.756523,59.9107589 692.815042,49.8302613 692.766356,39.7483458 C692.727024,29.6666667 692.627254,19.5845149 692.338498,9.5 L696,9.53946371 Z" id="Fill-172"></path>
                <path d="M730,15.5389233 C729.388826,24.8637643 729.019964,34.1912001 728.739883,43.5193437 L728.629764,47.017486 L728.547604,50.5158642 L728.383038,57.5123847 L728.170648,71.507077 L728.083337,85.5027128 C728.093148,90.1685539 728.071075,94.8339231 728.127728,99.5 C728.20155,108.831918 728.35557,118.164779 728.744298,127.5 L725,127.461313 C725.598175,118.136 725.961397,108.8088 726.24442,99.4804204 C726.406043,94.8167025 726.488449,90.151805 726.602737,85.4876153 L726.829107,71.4931589 L726.930887,57.4975231 L726.923284,50.4988795 L726.91936,46.9997936 L726.887968,43.5002359 C726.817089,34.168318 726.657674,24.8354564 726.255702,15.5 L730,15.5389233 Z" id="Fill-174"></path>
                <path d="M761,28.5350189 C760.424057,35.7777825 760.085502,43.0226755 759.832448,50.2682785 C759.681625,53.8908433 759.622411,57.514118 759.515666,61.1371561 L759.336697,72.0072167 L759.284918,82.8782239 C759.309612,86.502445 759.295805,90.1261929 759.36564,93.7506507 C759.462294,100.999093 759.640998,108.248482 760.053903,115.5 L756,115.464744 C756.570632,108.221981 756.907063,100.977088 757.161445,93.7314849 C757.310143,90.1089201 757.375199,86.4856454 757.478492,82.8626073 L757.663303,71.9927833 L757.720659,61.1215395 C757.693043,57.4973184 757.712693,53.8738071 757.640998,50.2493493 C757.545406,43.0006704 757.364578,35.7515183 756.946097,28.5 L761,28.5350189 Z" id="Fill-176"></path>
                <path d="M788,47.530896 C787.503901,51.5244398 787.238721,55.5198562 787.03127,59.5157406 C786.836022,63.5118591 786.725726,67.5086798 786.695923,71.5059685 C786.663772,75.5034914 786.704371,79.5014824 786.83039,83.5004096 C786.971429,87.4993368 787.162922,91.4987322 787.582986,95.5 L784,95.469104 C784.489528,91.4755602 784.750249,87.4801438 784.96122,83.4842594 C785.156703,79.4881409 785.266999,75.4913202 785.304077,71.4940315 C785.343972,67.4965086 785.303139,63.4985176 785.177589,59.4998245 C785.039601,55.5008972 784.843884,51.5012678 784.417248,47.5 L788,47.530896 Z" id="Fill-178"></path>
                <path d="M664,14.5389233 C663.407466,23.8640002 663.045715,33.191436 662.756806,42.5195796 C662.45686,51.8477232 662.287389,61.1770462 662.157649,70.5068411 C662.084809,75.1717385 662.081866,79.8373436 662.040418,84.5024769 L662.045568,91.5008846 C662.050719,93.8336872 662.046304,96.1664897 662.078432,98.4995282 C662.150047,107.831446 662.320498,117.164544 662.744298,126.5 L659,126.461313 C659.633247,117.136472 660.0129,107.809272 660.293717,98.4808922 C660.378084,96.1492691 660.426399,93.8167025 660.483543,91.4846076 L660.645657,84.4878512 C660.708932,79.8229538 660.810467,75.1585282 660.842351,70.4931589 C660.922058,61.1628923 660.961789,51.8321538 660.871045,42.5 C660.791583,33.1680821 660.639035,23.8352205 660.255702,14.5 L664,14.5389233 Z" id="Fill-180"></path>
                <path d="M633,26.535019 C632.419278,33.7775656 632.074084,41.0224784 631.794211,48.2678644 L631.597451,53.7019631 L631.453797,59.136535 C631.341211,62.7593463 631.300584,66.3831041 631.239511,70.0063886 C631.101434,77.2529577 631.123739,84.5007098 631.214817,91.7494085 L631.322623,97.1861099 L631.489379,102.623521 C631.631439,106.248462 631.778279,109.873639 632.053903,113.5 L628,113.464981 C628.354222,109.844063 628.580191,106.222198 628.801115,102.600096 L629.086033,97.1667075 L629.312002,91.7328454 C629.560807,84.4872228 629.740839,77.2411269 629.760489,69.9933748 C629.778279,66.3698536 629.816516,62.7463325 629.782793,59.1221015 L629.757302,53.6861099 L629.678704,48.2494085 C629.556559,41.0007098 629.369092,33.751538 628.946097,26.5 L633,26.535019 Z" id="Fill-182"></path>
                <path d="M605,44.530896 C604.732708,46.5274339 604.547081,48.5246739 604.375535,50.522148 C604.203755,52.5196221 604.066002,54.5173302 603.955471,56.5150384 C603.725491,60.5109228 603.596656,64.5075094 603.530478,68.5045642 C603.460546,72.5016189 603.477677,76.4996099 603.623643,80.4985371 C603.786037,84.4976984 604.024699,88.4975619 604.582752,92.5 L601,92.469104 C601.62775,88.4767305 601.935641,84.4817823 602.167967,80.4861319 C602.383397,76.4900134 602.469757,72.4931927 602.469522,68.4954358 C602.472807,64.4976789 602.41367,60.4994539 602.253153,56.5002926 C602.177589,54.500946 602.074333,52.5011313 601.937284,50.5010825 C601.800469,48.5010338 601.649809,46.500985 601.417014,44.5 L605,44.530896 Z" id="Fill-184"></path>
                <path d="M558,47.5328442 C557.430214,51.2752155 557.159871,55.020382 556.953124,58.7660144 C556.761631,62.5118798 556.695219,66.2589099 556.695219,70.0064058 C556.691698,73.7539017 556.749193,77.5020964 556.880845,81.250757 C557.029627,84.9996506 557.20704,88.7487771 557.582752,92.5 L554,92.4671558 C554.445409,88.7236198 554.69205,84.9782204 554.910531,81.2328209 C555.11188,77.4869555 555.238838,73.7406243 555.304781,69.9935942 C555.374479,66.2467971 555.37753,62.4990683 555.2555,58.7504076 C555.118451,55.0015141 554.917571,51.2521547 554.417248,47.5 L558,47.5328442 Z" id="Fill-186"></path>
                <path d="M532,27.5361222 C531.469995,34.5291968 531.156134,41.5241602 530.925385,48.5195957 C530.79607,52.0173135 530.730483,55.5155034 530.636219,59.0136934 C530.586033,62.5121194 530.509559,66.0100733 530.480616,69.5087354 C530.423261,76.5058236 530.39777,83.503384 530.486989,90.5018887 C530.565852,97.5001574 530.709772,104.499134 531.053903,111.5 L527,111.463642 C527.502124,104.470567 527.80324,97.4756037 528.040096,90.4799321 C528.287042,83.4844966 528.419278,76.4881167 528.519384,69.4912646 C528.569304,65.9928385 528.57196,62.4939403 528.600372,58.9955142 C528.585236,55.4963799 528.597982,51.9977178 528.547531,48.4985834 C528.474774,41.5000787 528.31864,34.5011018 527.946097,27.5 L532,27.5361222 Z" id="Fill-188"></path>
                <path d="M501,14.5399237 C500.404768,23.6981343 500.043263,32.8587213 499.762447,42.0202589 C499.601315,46.60079 499.521362,51.1820341 499.406092,55.7630406 L499.181439,69.5072481 L499.082356,83.2528814 L499.088733,90.126411 L499.092902,93.5631758 L499.123804,97.0001782 C499.193211,106.16528 499.349438,115.331333 499.744298,124.5 L496,124.460076 C496.604307,115.302103 496.96949,106.141516 497.248345,96.9799787 L497.357728,93.5446398 L497.440133,90.1088256 L497.603473,83.2371971 L497.818561,69.4927519 L497.908079,55.746881 C497.897533,51.1646863 497.922058,46.5824917 497.86565,41.9998218 C497.794035,32.8344819 497.641732,23.6684292 497.255702,14.5 L501,14.5399237 Z" id="Fill-190"></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
    
    <transition name="slide-fade">
      <div class="columns is-centered pscore-loading-dark" v-if="['step1', 'step2', 'step3'].indexOf(this.step)>=0">
        <div class="column has-text-centered is-uppercase pt-step">{{$t('components.PScoreLoading.step1')}}</div>
      </div>
    </transition>

    <transition name="slide-fade">
      <div class="columns is-centered pscore-loading-dark" v-if="['step2', 'step3'].indexOf(this.step)>=0">
        <div class="column has-text-centered is-uppercase pt-step">{{$t('components.PScoreLoading.step2')}}</div>
      </div>
    </transition>

    <transition name="slide-fade">
      <div class="columns is-centered pscore-loading-dark" v-if="['step3'].indexOf(this.step)>=0">
        <div class="column has-text-centered is-uppercase pt-step">{{$t('components.PScoreLoading.step3')}}</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PScoreLoading',
  data () {
    return {
      step: 'step1'
    };
  },
  mounted: function() {
    setTimeout(() => {
      this.step = 'step2';

      setTimeout(() => {
        this.step = 'step3';
      }, 1500);
    }, 1500);
  }
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.pt-step {
  font-family: 'Gilroy Bold';
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 2px;
}

.pscore-loading-light {
  fill: #C3ADCA !important;
}
  
.pscore-loading-dark {
  fill: $color01;
  color: $color01;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-enter {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
