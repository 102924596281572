<template>
  <div class="block">
    <YesNoModal :isActive="sharePScoreLoadingModalOpen" 
              :title="$t('components.PScoreResult.sharePScoreModalTitle')">
      <div class="block">
        <Loading color="#FFF" />
      </div>
    </YesNoModal>

    <YesNoModal :isActive="sharePScoreSuccessModalOpen" 
              @yes="sharePScoreSuccessModalOpen=false" 
              @no="sharePScoreSuccessModalOpen=false"
              :yesText="$t('common.close')"
              :title="$t('components.PScoreResult.sharePScoreSuccessModalTitle')">
      <div class="block">
        <p>{{$t('components.PScoreResult.sharePScoreSuccessModalBody')}}</p>
      </div>
    </YesNoModal>

    <YesNoModal :isActive="sharePScoreErrorModalOpen" 
              @yes="sharePScoreErrorModalOpen=false;sharePScoreModalOpen=true;" 
              @no="sharePScoreErrorModalOpen=false;" 
              :yesText="$t('common.close')"
              :title="$t('components.PScoreResult.sharePScoreErrorModalTitle')">
      <div class="block">
        <p>{{$t('components.PScoreResult.sharePScoreErrorModalBody')}}</p>
      </div>
    </YesNoModal>

    <YesNoModal :isActive="sharePScoreModalOpen" 
              @yes="sharePScore(shareEmail, sharePhone)" 
              :yesText="$t('common.send')"
              @no="sharePScoreModalOpen=false" 
              :noText="$t('common.cancel')"
              :title="$t('components.PScoreResult.sharePScoreModalTitle')">
      <div class="block">
        <p>{{$t('components.PScoreResult.sharePScoreModalBody1')}}</p>
        <p>{{$t('components.PScoreResult.sharePScoreModalBody2')}}</p>
      </div>

      <div class="block">
        <div class="field">
          <label class="label">{{$t('common.email')}}</label>
          <div class="control">
            <input type="email" class="input" :placeholder="$t('common.email')" v-model="shareEmail" :class="{'is-error': shareEmailError}">
          </div>
          <p class="help has-text-danger" v-if="shareEmailError">{{$t('components.PScoreResult.shareEmailError')}}</p>
        </div>

        <div class="field">
          <label class="label">{{$t('components.PScoreResult.mobilePhone')}}</label>
          <div class="control">
            <input type="text" class="input" :placeholder="$t('components.PScoreResult.mobilePhone')" v-model="sharePhone" :class="{'is-error': sharePhoneError}">
          </div>
          <p class="help has-text-danger" v-if="sharePhoneError">{{$t('components.PScoreResult.sharePhoneError')}}</p>
        </div>
      </div>
    </YesNoModal>

    <Loading v-if="isLoading"/>

    <div v-else-if="score">
      <div class="block">
        <h1 class="title">{{$t('components.PScoreResult.title')}}</h1>
      </div>
      
      <div class="block">
        <PScoreInputDisplay :score="score" />
      </div>

      <div class="block">
        <PScoreDiagram :pscore="score.result.PScore" :risk="score.result.PScoreRisk"/>
      </div>

      <div class="block">
        <PScoreSignedBy :pscore="score" />
      </div>

      <div class="block">
        <div v-if="!updateCommentOpen">
          <p class="mb-2" v-if="score.input.comment" style="word-wrap: break-word;"><span class="has-text-weight-bold">{{$t('components.PScoreResult.comment')}}:</span> {{score.input.comment}}</p>

          <button class="button" @click="updateCommentOpen=true;">{{score.input.comment ? $t('components.PScoreResult.editComment') : $t('components.PScoreResult.addComment')}}</button>
        </div>
        <div v-else>
          <textarea class="textarea" v-model="score.input.comment" style="width: 100%;" rows="3" maxlength="400"></textarea>

          <div class="block">
            <p class="help has-text-danger mt-2" v-if="commentError">{{$t('components.PScoreResult.commentError')}}</p>

            <button class="button is-primary mt-2" @click="updateComment(score.input.comment)" :disabled="isUpdatingComment">
              <span style="margin-right: 10px;" v-if="isUpdatingComment"><FontAwesomeIcon :icon="['fa', 'spinner']" pulse/></span>
              <span>{{$t('components.PScoreResult.save')}}</span>
            </button>
          </div>
        </div>
      </div>

      <div class="block">
        <LoadingButton class="button is-primary mt-4"
          @click="downloadPscoreReport()"
          :text="$t('components.PScoreResult.print')"
          :disabled="isDownloadingReport"
          :isLoading="isDownloadingReport" />
      </div>

      <div class="block">
        <button class="button" @click="sharePScoreModalOpen=true;">{{$t('components.PScoreResult.send')}}</button>
      </div>

      <div class="block">
        <PScoreResultFooter />
      </div>

      <div class="block">
        <Version />
      </div>
    </div>
    <div v-else>
      <SomethingWentWrong />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { trim } from 'lodash';

import Settings from '@/Settings';
import Utils from '@/Utils';
import Loading from '@/components/shared/Loading.vue';
import LoadingButton from '@/components/shared/LoadingButton.vue';
import YesNoModal from '@/components/shared/YesNoModal.vue';
import Version from '@/components/shared/Version.vue';
import PScoreInputDisplay from '@/components/PScoreResult/PScoreInputDisplay.vue';
import PScoreDiagram from '@/components/PScoreResult/PScoreDiagram/PScoreDiagram.vue';
import PScoreSignedBy from '@/components/PScoreResult/PScoreSignedBy.vue';
import SomethingWentWrong from '@/components/shared/SomethingWentWrong.vue';
import PScoreResultFooter from '@/components/PScoreResult/PScoreResultFooter.vue';
import { mapState } from 'vuex';

export default {
  name: 'PScoreResult',
  props: [ 'scoreId' ],
  components: {
    Loading,
    LoadingButton,
    Version,
    YesNoModal,
    PScoreInputDisplay,
    PScoreDiagram,
    PScoreSignedBy,
    SomethingWentWrong,
    PScoreResultFooter
  },
  data () {
    return {
      isLoading: false,

      isDownloadingReport: false,

      sharePScoreModalOpen: false,
      sharePScoreLoadingModalOpen: false,
      sharePScoreSuccessModalOpen: false,
      sharePScoreErrorModalOpen: false,

      updateCommentOpen: false,
      isUpdatingComment: false,
      commentError: false,

      isSharingPScore: false,

      score: null,
      error: false,


      shareEmail: '',
      sharePhone: '',
      shareEmailError: false,
      sharePhoneError: false
    };
  },
  created () {
    this.fetchData();
  },
  watch: {
    '$route': 'fetchData'
  },
  computed: {
    ...mapState({ currentLanguage: state => state.user.lang })
  },
  methods: {
    async updateComment(comment) {
      try {
        this.isUpdatingComment = true;
        this.commentError = false;

        await axios.put(`${Settings.apiUrl}/pscores/${this.scoreId}/comment`, {
          comment
        });
        
        this.updateCommentOpen = false;
      } catch (error) {
        this.commentError = true;

        console.error(error);
      } finally {
        this.isUpdatingComment = false;
      }
    },
    async fetchData () {
      try {
        this.error = false;
        this.score = null;
        this.isLoading = true;

        const score = (await axios.get(`${Settings.apiUrl}/pscores/${this.scoreId}`)).data;
        if (score.id === this.scoreId) {
          this.score = score;
        }
      } catch (error) {
        console.error(error);
        this.error = false;
      } finally {
        this.isLoading = false;
      }
    },
    validate() {
      this.shareEmailError = false;
      this.sharePhoneError = false;

      let result = true;
      if (!this.shareEmail || !Settings.emailRegex.test(this.shareEmail)) {
        this.shareEmailError = true;
        result = false;
      }

      if (!this.sharePhone || !this.sharePhone.startsWith('+')) {
        this.sharePhoneError = true;
        result = false;
      }

      return result;
    },
    async downloadPscoreReport() {
      try {
        this.isDownloadingReport = true;

        const report = await axios.get(`${Settings.apiUrl}/pscores/${this.scoreId}/printed`, { params: { lang: this.currentLanguage } });

        Utils.downloadFile(report.data.name, report.data.base64);
      } catch (error) {
        console.error(error);
      } finally {
        this.isDownloadingReport = false;
      }
    },
    async sharePScore(email, phone) {
      try {
        if (!this.validate(email, phone)) {
          return;
        }

        email = trim(email);
        phone = trim(phone);

        this.sharePScoreModalOpen = false;
        this.sharePScoreLoadingModalOpen = true;

        await axios.post(`${Settings.apiUrl}/pscores/${this.scoreId}/share`, {
          secureMail: {
              email,
              phone,
          },
          lang: this.currentLanguage
        });

        this.sharePScoreLoadingModalOpen = false;
        this.sharePScoreSuccessModalOpen = true;

        this.shareEmail = '';
        this.sharePhone = '';
      } catch (error) {
        this.sharePScoreLoadingModalOpen = false;
        this.sharePScoreErrorModalOpen = true;

        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 374px) { 
    .button {
      min-width: 100%;
      font-size: .8em;
    }
  }
</style>
