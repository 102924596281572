import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

Vue.use(Vuex);

const initialUserState = {
  isLoading: false,
  isLoggedIn: false,
  loginState: null,

  authorization: {
    token: null,
    tokenType: null,
    tokenExpiration: null,
    refreshToken: null,
    refreshTokenExpiration: null,
    scope: null
  },

  me: {
    id: null,
    email: null,
    name: null,
    firstName: null,
    lastName: null,
    roles: []
  },

  version: null,

  lang: 'en'
};

const user = {
  namespaced: true,
  state: { ...initialUserState },
  mutations: {
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setLoginState(state, loginState) {
      state.loginState = loginState;
    },

    setLanguage(state, lang) {
      state.lang = lang;
    },

    setMe(state, me) {
      me.isAdmin = (me.roles || []).indexOf('administrator') >= 0;
      me.isLabWorker = (me.roles || []).indexOf('laboratory') >= 0;
      state.me = { ...me };
    },
    setAuthorization(state, authorization) {
      state.authorization = { ...authorization };
    },
    setVersion(state, version) {
      state.version = version;
    },
    reset(state) {
      state.isLoading = initialUserState.isLoading;
      state.isLoggedIn = initialUserState.isLoggedIn;
      state.loginState = initialUserState.loginState;

      state.authorization = { ...initialUserState.authorization };
      state.me = { ...initialUserState.me };
      state.version = initialUserState.version;
    }
  }
}

export default new Vuex.Store({
  namespaced: true,
  modules: {
    user
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    // https://github.com/robinvdvleuten/vuex-persistedstate
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
});
