<template>
  <div class="block">
    <div class="block">
      <Logo />
    </div>

    <div class="block">
      <h1 class="title">{{$t('components.PScoreResult.title')}}</h1>
    </div>

    <div class="block mb-0">
      <PScoreInputDisplay :score="score" />
    </div>

    <div class="block mb-0">
      <PScoreDiagram :pscore="score.result.PScore" :risk="score.result.PScoreRisk" hideBorder="true" />
    </div>

    <div class="block mb-2">
      <PScoreSignedBy :pscore="score" />
    </div>

    <div class="block mb-2" v-if="score.input.comment">
      <p style="word-wrap: break-word; line-height:20px;"><span class="has-text-weight-bold">{{$t('components.PScoreResult.comment')}}:</span> {{score.input.comment}}</p>
    </div>

    <div class="block">
      <PScoreResultFooter />
    </div>
  </div>
</template>

<script>
import Logo from '@/components/shared/Logo.vue';
import PScoreInputDisplay from '@/components/PScoreResult/PScoreInputDisplay.vue';
import PScoreDiagram from '@/components/PScoreResult/PScoreDiagram/PScoreDiagram.vue';
import PScoreSignedBy from '@/components/PScoreResult/PScoreSignedBy.vue';
import PScoreResultFooter from '@/components/PScoreResult/PScoreResultFooter.vue';

export default {
  name: 'PScoreResultPrinted',
  components: {
    Logo,
    PScoreInputDisplay,
    PScoreDiagram,
    PScoreSignedBy,
    PScoreResultFooter
  },
  data: function () {
    return {
      score: {
        id: this.$route.params.id,
        input: {
          dateOfBirth: this.getStringQueryKey('dateOfBirth'),
          kitLotId: this.getStringQueryKey('kitLotId'),

          deltaCpIGFBP3: this.getNumberQueryKey('deltaCpIGFBP3'),
          deltaCpF3: this.getNumberQueryKey('deltaCpF3'),
          deltaCpVGLL3: this.getNumberQueryKey('deltaCpVGLL3'),

          gleasonScorePrimary: this.getNumberQueryKey('gleasonScorePrimary'),
          gleasonScoreSecondary: this.getNumberQueryKey('gleasonScoreSecondary'),

          PSA: this.getNumberQueryKey('PSA'),
          TStage: this.getStringQueryKey('TStage'),

          comment: this.getStringQueryKey('comment')
        },
        result: {
          PScore: this.getNumberQueryKey('PScore'),
          PScoreRisk: this.getStringQueryKey('PScoreRisk')
        },
        metadata: {
          signedByName: this.getStringQueryKey('signedByName'),
          createdByInitials: this.getStringQueryKey('createdByInitials'),
          createdAt: this.getStringQueryKey('createdAt')
        }
      }
    }
  },
  methods: {
    getNumberQueryKey: function(key) {
      return parseFloat(this.getStringQueryKey(key));
    },
    getStringQueryKey: function(key) {
      if (!this.$route.query[key]) {
        return '';
      }

      return this.$route.query[key];
    }
  }
}
</script>

