<template>
  <div>

    <div class="dropdown" :class="{ 'is-active': isDropdownMenuOpen }" v-if="!isMobileLangMenuOpen">
      <div class="dropdown-trigger">
        <button
          class="button dropdown-button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          @click="onToggleLangMenu"
        >
          <country-flag :country="selectedLanguageFlag" size="normal" />
          <span class="icon is-small m-0">
            <FontAwesomeIcon
              :icon="['fa', 'angle-down']"
              aria-hidden="true"
              v-if="!isDropdownMenuOpen"
            />
            <FontAwesomeIcon
              :icon="['fa', 'angle-up']"
              aria-hidden="true"
              v-else
            />
          </span>
        </button>
      </div>
      <div class="dropdown-container">
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <div
              v-for="(language, i) in getAvailableLanguages"
              :key="`Lang${i}`"
              class="dropdown-item-container is-flex is-justify-content-space-between is-align-content-center"
              :class="{ 'active-lang': currentLanguage === language.langCode }"
              @click="onChangeLocale(language.langCode)"
            >
              <div class="dropdown-lang-title">
                {{$t(`languages.${language.title}`)}}
              </div>
              <div class="dropdown-lang-flag">
                <country-flag :country="language.flagCode" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-centered" v-for="language in getAvailableLanguages" :key="language.title" v-else>
      <div class="column has-text-centered">
        <div 
          class="navbar-item is-flex is-justify-content-space-between is-clickable"
          @click="$emit('toggleMobileLangMenu'); onChangeLocale(language.langCode)"
        >
          <span 
            class="mobile-lang-link my-auto py-auto"
            :class="{ 'active-mobile-lang-link': currentLanguage === language.langCode }"
          >
            {{ $t(`languages.${language.title}`).toUpperCase() }}</span> 
          <country-flag :country="language.flagCode" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  loadLanguageAsync,
  AVAILABLE_LANGUAGES
} from '@/i18n';
import CountryFlag from 'vue-country-flag';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'LanguageSwitcher',
  components: { CountryFlag },
  props: {
    isMobileLangMenuOpen: {
      type: Boolean,
      default: false
    },
    isBurgerActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDropdownMenuOpen: false
    };
  },
  computed: {
    ...mapState({ currentLanguage: state => state.user.lang }),
    selectedLanguageFlag: function() {
      const flag = this.getAvailableLanguages.find(
        languageObject => languageObject.langCode === this.currentLanguage
      );
      return flag.flagCode;
    },
    getAvailableLanguages: function() {
      return AVAILABLE_LANGUAGES;
    }
  },
  methods: {
    ...mapMutations({ setLanguage: 'user/setLanguage' }),
    onToggleLangMenu: function() {
      if (this.isBurgerActive) {
        return this.$emit('toggleMobileLangMenu');
      } else {
        return this.isDropdownMenuOpen = !this.isDropdownMenuOpen;
      }
    },
    onChangeLocale: async function(languageCode) {
      if (languageCode && languageCode !== this.currentLanguage) {
        this.setLanguage(languageCode);
      }

      if (this.isMobileLangMenuOpen) {
        this.$emit('toggleMobileLangMenu');
      }
      if (this.$route.query.lang) {
        this.$router.push(this.$route.path);
      }

      this.onToggleLangMenu();

      return await loadLanguageAsync(languageCode);
    },
    closeDropdownMenu: function (e) {
      if (!this.$el.contains(e.target)) {
        this.isDropdownMenuOpen = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeDropdownMenu);
  },
  beforeDestroy() {
    document.removeEventListener('click',this.closeDropdownMenu);
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.dropdown {
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 35px;
}

.dropdown-button {
  height: 35px;
  min-width: 85px;
  padding: 1px;
  margin: 0;
}

.dropdown-menu {
  left: -50px;
}

.dropdown-item-container {
  cursor: pointer;
  height: auto;
  height: 40px;
}

.dropdown-item-container:hover {
  background-color: $color02;
  cursor: pointer;
}

.dropdown-lang-title {
  margin: auto 10px;
}

.dropdown-lang-flag {
  margin: auto 10px;
}

.active-lang {
  background-color: $color01;
  color: #ffff;
  pointer-events: none;
}

.mobile-lang-link {
  color: $color01;
  letter-spacing: 2px;
}

.mobile-lang-link:hover {
  color: $color03;
}

.active-mobile-lang-link {
  border-bottom: 3px solid !important;
}

@media screen and (max-height: 415px) {
  .columns {
    max-height: 2.8rem !important;
  }
}

@media screen and (max-height: 320px) {
  .columns {
    max-height: 2.3rem !important;
  }
}

@media screen and (max-height: 280px) {
  .columns {
    max-height: 2rem !important;
  }
}
</style>
