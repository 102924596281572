<template>
  <div class="hero is-fullheight">
    <div class="hero-body">
      <div class="block container has-text-centered">
        <div class="block mt-6 is-hidden-mobile">
          <div class="is-flex is-justify-content-space-between is-align-items-center">
            <Logo />
            <LanguageSwitcher />
          </div>
        </div>

        <div class="hero-body-content border-01">
          <div class="block mb-5 is-hidden-tablet has-text-left">
            <Logo />
          </div>

          <h1 class="block title" style="margin-bottom:10px;">{{$t('views.login.welcome')}}</h1>
          <h1 class="block title">{{$t('views.login.signIn')}}</h1>

          <div class="block mb-5 is-hidden-tablet is-flex is-justify-content-center">
            <LanguageSwitcher />
          </div>
          
          <div class="block">
            <div class="columns is-centered">
              <div class="column">
                <div v-if="user.isLoading">
                  <Loading />
                </div>
                <div class="box has-text-centered" v-else>
                  <div class="login-header">
                    <button class="button is-primary" @click="login()">
                      <span class="icon is-small">
                        <FontAwesomeIcon :icon="['fa', 'lock']" />
                      </span>
                      <span>{{$t('views.login.button')}}</span>
                    </button>
                  </div>
                  <div class="login-footer">
                    <div style="line-height: 16px;">
                      <span>{{$t('views.login.loginDescription')}}</span>
                    </div>
                    <div>
                      <a href="https://www.secureappbox.com" target="_blank"><span style="color: #747474;">{{$t('views.login.poweredBy')}}</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="block" v-if="false">
            <label class="label">{{$t('views.login.noAccount')}}</label>
          </div>

          <div class="block" v-if="false">
            <a href style="text-decoration: underline;">{{$t('views.login.apply')}}</a>
          </div>
        </div>

        <CompanyInfo :is-vertical="true" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { pick } from 'lodash';
import { mapState } from 'vuex';

import store from '@/store';
import router from '@/router';

import Settings from '@/Settings';
import Logo from '@/components/shared/Logo.vue';
import CompanyInfo from '@/components/shared/CompanyInfo.vue';
import Loading from '@/components/shared/Loading.vue';
import LanguageSwitcher from '../components/shared/LanguageSwitcher.vue';

const REDIRECT_URI = `${document.location.origin}/login`;

function getRandomId() {
  var text = '',
    possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < 10; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text + new Date().getTime();
}

function setSuccessfullLogin(data) {
  store.commit('user/setMe', pick(data, Settings.USER_FIELDS));

  store.commit('user/setIsLoggedIn', true);
  store.commit('user/setIsLoading', false);

  router.push({ name: 'home' });
}

function setAuthorization(data) {
  store.commit('user/setAuthorization', pick(data, Settings.AUTH_FIELDS));
}

function setLoginState(state) {
  store.commit('user/setLoginState', state);
}

function setLoading() {
  store.commit('user/setIsLoading', true);
}

function setFailedLogin(error, errorMessage) {
    console.error(error);

    store.commit('user/reset');

    store.commit('user/setIsLoggedIn', false);
    store.commit('user/setIsLoading', false);

    router.push({
      name: 'login',
      query: {
        errorMessage
      }
    });
}

function resetUser() {
  store.commit('user/reset');
}

export default {
  name: 'LoginView',
  components: {
    Logo,
    Loading,
    CompanyInfo,
    LanguageSwitcher
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  methods: {
    login: function () {
      const state = getRandomId();
      setLoginState(state);

      const url = `${Settings.secureAppboxUrl}/oauth/authorize?client_id=${Settings.secureAppboxClientId}&response_type=code&state=${state}&scope=account_information%20messages_send&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;
      window.location.href = url;
    }
  },
  created: async function () {
    if (store.state.user.isLoggedIn) {
      setLoading();

      try {
        const response = await axios.get(`${Settings.apiUrl}/me`);

        setSuccessfullLogin(response.data);
      } catch(error) {
        setFailedLogin(error, 'Authorization expired');
        this.flash(this.$t('views.login.unauthorized'), 'error');
      }
    } else if (store.state.user.loginState && this.$route.query.state && store.state.user.loginState === this.$route.query.state && this.$route.query.code) {
      setLoginState(null);
      setLoading();

      try {
        const response = await axios.post(`${Settings.apiUrl}/login`, { redirectUri: REDIRECT_URI, code: this.$route.query.code });

        setAuthorization(response.data);
        setSuccessfullLogin(response.data.user);
      } catch(error) {
        setFailedLogin(error, 'Unauthorized');
        this.flash(this.$t('views.login.unauthorized'), 'error');
      }
    } else {
      if (this.$route.query.error) {
        console.error(`Failed to authorize from SA: ${this.$route.query.error}`);
        this.flash(this.$t('views.login.unauthorized'), 'error');
      }

      resetUser();
    }
  }
}
</script>

<style lang="scss" scoped>
@charset "utf-8";

$login-border-radius: 6px;
$login-outer-margin: -20px;

.button {
  border-color: #FFF !important;
  min-width: 200px;
  padding-left: 25px;
  padding-right: 25px;
}

@media screen and (min-width: 769px) {
  .column {
    max-width: 520px;
  }
}

.hero-body {
  padding-top: 10px;
  padding-bottom: 0;
}

.hero-body-content {
  background-color: #fff;
  padding: 30px 50px 30px 50px;
  margin-bottom: 20px;
}

.login-header {
  font-size: 0.85rem;
  background-color: #777;
  color: #fff;
  padding: 15px 65px 15px 65px; 
  margin-right: $login-outer-margin;
  margin-left: $login-outer-margin;
  margin-top: $login-outer-margin;
  border-top-left-radius: $login-border-radius;
  border-top-right-radius: $login-border-radius;
}

@media screen and (max-width: 768px) {
  .column {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  .button {
    font-size: 0.875em;
  }

  .login-header {
    padding: 15px 10px 15px 10px; 
  }

  .login-footer {
    padding: 10px 10px 10px 10px;
  }

  .hero {
    background-color: #fff;
  }
  
  .hero-body, .hero-foot {
    padding: 0;
  }

  .hero-body-content {
    background-color: #fff;
    padding-left: 0;
    padding-right: 0;
    border: 0;
    margin-bottom: 0;
  }
}

.login-footer {
  font-size: 0.75rem;
  background-color: #444;
  color: #bfbfbf;
  padding: 10px 20px 10px 20px;
  margin-right: $login-outer-margin;
  margin-left: $login-outer-margin;
  margin-bottom: $login-outer-margin;
  border-bottom-left-radius: $login-border-radius;
  border-bottom-right-radius: $login-border-radius;
}
@media screen and (max-width: 374px) { 
  .button {
    font-size: 0.7em;
  }
}
</style>