<template>
  <div class="block menu-content" style="margin: 20px 0 0 0;">
    <div class="columns">
      <div class="column has-text-right">
        <label class="label" style="padding: 0 25px 0 12px;">{{this.user.me.name}}, {{this.user.me.email}}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UserInfo',
  computed: {
    ...mapState([
      'user'
    ])
  }
}
</script>
