<script>
import { HorizontalBar } from 'vue-chartjs';

import Utils from '@/Utils';
import Settings from '@/Settings';

const GREEN_LENGTH = 2.5,
  YELLOW_LENGTH = 3,
  RED_LENGTH = Settings.maxPScoreValue - GREEN_LENGTH - YELLOW_LENGTH,
  TICKS_STEP_SIZE = 2;

export default {
  name: 'PScoreBar',
  extends: HorizontalBar,
  mounted () {
    this.renderChart({
      type: 'horizontalBar',
      labels: [ 'a' ],
      datasets: [{
        categoryPercentage: 1.0,
        barPercentage: 0.55,
        backgroundColor: '#36AD8B', // green
        data: [ GREEN_LENGTH ],
        options: {}
      }, {
        categoryPercentage: 1.0,
        barPercentage: 0.55,
        backgroundColor: '#F6CF7C', // yellow
        data: [ YELLOW_LENGTH ],
        options: {}
      }, {
        categoryPercentage: 1.0,
        barPercentage: 0.55,
        backgroundColor: '#CE3356', // red
        data: [ RED_LENGTH ],
        options: {}
      }] }, {
        layout: {
          padding: {
            right: 62
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        events: [],
        defaultFontFamily: 'Gilroy',
        defaultFontSize: 16,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            scaleLabel: {
              display: false
            },
            gridLines: {
              display: false
            },
            ticks: {
              display: true,
              stepSize: TICKS_STEP_SIZE,
              max: Settings.maxPScoreValue,
              min: 0,
              beginAtZero: true,
              fontFamily: 'Gilroy Bold',
              fontWeight: 400,
              fontSize: 16,
              fontColor: '#652F6C',
            },
            afterBuildTicks: function(chart) {    
              chart.ticks = Utils.getArrayOfNumbers(Settings.maxPScoreValue / TICKS_STEP_SIZE, i => parseInt(i * TICKS_STEP_SIZE));
            }
          }],
          yAxes: [{
            stacked: true,
            position: 'right',
            lineWidth: 5,
            gridLines: {
              display: false
            },
            ticks: {
              display: false
            }
          }]
        }
    });
  }
}
</script>