<template>
  <div class="is-clipped">
    <div class="modal is-clipped" :class="[isActive ? 'is-active' : '']">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <div class="has-text-right" style="position: absolute; right: 10px; top: 10px;">
            <button class="delete is-large button-close" aria-label="close" @click="no()"></button>
          </div>
          <h1 class="title block" style="margin-top: 10px;">{{title}}</h1>

          <slot></slot>

          <div class="block columns is-mobile">
            <div class="column is-half" v-if="yesText">
              <button class="button is-primary" @click="yes()" :disabled="isYesDisabled">{{yesText}}</button>
            </div>
            <div class="column is-half" v-if="noText">
              <button class="button" @click="no()" :disabled="isNoDisabled">{{noText}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YesNoModal',
  props: [ 'isActive', 'yesText', 'noText', 'isYesDisabled', 'isNoDisabled', 'title', 'body', 'onYes', 'onNo' ],
  methods: {
    yes() {
      this.$emit('yes');
    },
    no() {
      this.$emit('no');
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal .modal-content {
    background-color: #454343;
  }

  .button {
    width: 100%;
    min-width: 50px;
  }

  .label {
    color: #FFF;
  }
</style>
