<template>
  <div style="position: relative;" v-if="stage!=='production'">
    <div class="stage-badge-container">
      <h1 class="stage-badge">{{stage}}</h1>
    </div>
  </div>
</template>

<script>
import Settings from '@/Settings';

export default {
  name: 'StageBadge',
  computed: {
    stage: function() {
      return Settings.stage;
    }
  }
}
</script>

<style lang="scss" scoped>
$badge-color: rgba($color: #e83030, $alpha: .8);

.stage-badge-container {
  position: absolute;
  top: 0;
  right: -45px;
  z-index: 1000;
}

.stage-badge {
  padding: 5px;
  font-size: 12px;
  transform: rotate(45deg);
  color: $badge-color;
  border: 4px dashed $badge-color;
}
</style>

