<template>
  <div class="pscore-dimmed-text">
    <div class="block mb-3">
      <p>{{$t('components.PScoreResult.footer')}}</p>
    </div>

    <div class="block mb-3">
      <ProstatypeShortDescription />
    </div>

    <div class="block mb-3">
      <p>{{$t('components.PScoreResult.disclaimer')}}</p>
    </div>

    <div class="block">
      <CompanyInfo />
    </div>
  </div>
</template>

<script>
import ProstatypeShortDescription from '@/components/shared/ProstatypeShortDescription.vue';
import CompanyInfo from '@/components/shared/CompanyInfo.vue';

export default {
  name: 'PScoreResultFooter',
  components: {
    ProstatypeShortDescription,
    CompanyInfo
  }
}
</script>

<style lang="scss" scoped>
.pscore-dimmed-text {
  font-size: 13px !important;
}
</style>
