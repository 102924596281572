<template>
  <button @click="clicked">
    <span style="margin-right: 10px;" v-if="isLoading"><FontAwesomeIcon :icon="['fa', 'spinner']" pulse/></span>
    <span>{{text}}</span>
  </button>
</template>

<script>
export default {
  name: 'LoadingButton',
  props: [ 'text', 'isLoading' ],
  methods: {
    clicked() {
      this.$emit('click');
    }
  }
}
</script>
