<template>
  <div v-if="isVertical">
    <div class="columns is-mobile  is-vcentered">
      <div class="column is-5">
        <div class="columns is-mobile">
          <div class="column is-narrow">
            <figure class="image is-32x32">
              <p class="has-text-center">
                <img src="/img/logo-prostatypegenomics32@2x.png" width="32" height="auto">
              </p>
            </figure>
          </div>
          <div class="column">
            <p class="has-text-left">
              <span class="has-text-weight-bold">{{companyInfo.name}}</span><br>
              {{companyInfo.address.street}}<br>
              {{companyInfo.address.zipcode}} {{companyInfo.address.city}}<br>
              {{companyInfo.address.country}}<br>
              <a :href="`mailto:${companyInfo.email}`">{{companyInfo.email}}</a><br>
              <a :href="`https://${companyInfo.url}`" target="_blank">{{companyInfo.url}}</a>
            </p>
          </div>
        </div>
      </div>
    <div class="column is-2 is-offset-5 has-text-right">
      <figure class="image is-64x64 mt-2">
        <p class="has-text-center">
          <img src="/img/ce-mark64@2x.png" width="64" height="auto">
        </p>
      </figure>
    </div>
    </div>
  </div>
  <div v-else>
    <p><span class="has-text-weight-bold">{{companyInfo.name}}</span>, {{companyInfo.address.street}}, {{companyInfo.address.zipcode}} {{companyInfo.address.city}}, {{companyInfo.address.country}}</p>
    <div class="mt-4">
      <div class="columns">
        <div class="column is-narrow mt-0 pt-0 pb-0">
          <a :href="`tel:${companyInfo.phone.value}`">{{companyInfo.phone.text}}</a>
        </div>
        <div class="column is-narrow mt-0 pt-0 pb-0">
          <a :href="`mailto:${companyInfo.email}`">{{companyInfo.email}}</a>
        </div>
        <div class="column is-narrow mt-0 pt-0 pb-0">
          <a :href="`https://${companyInfo.url}`" target="_blank">{{companyInfo.url}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyInfo',
  props: {
    isVertical: Boolean
  },
  data: function() {
    return {
      companyInfo: {
        name: 'Prostatype Genomics AB',
        address: {
          street: 'Gustav III:s Boulevard 34',
          city: 'Solna',
          zipcode: '169 73',
          country: 'Sweden'
        },
        phone: {
          text: '+46 (0)8-20 87 00',
          value: '+468208700'
        },
        email: 'info@prostatypegenomics.com',
        url: 'www.prostatypegenomics.com'
      }
    };
  }
}
</script>
