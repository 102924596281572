<template>
  <div class="date-picker field">
    <label class="label">{{label}}</label>

    <div class="control">
      <div class="field columns is-mobile is-1">
        <div class="column is-4 input-left">
          <div class="select" :class="{'is-error': error}">
            <select v-model="year" @change="yearUpdated()">
              <option disabled value="">{{$t('components.DatePicker.year')}}</option>
              <option v-for="option in yearOptions" :value="option" :key="option">{{option}}</option>
            </select>
          </div>
        </div>

        <div class="column is-4 input-center">
          <div class="select" :class="{'is-error': error}">
            <select v-model="month" @change="monthUpdated()">
              <option disabled value="">{{$t('components.DatePicker.month')}}</option>
              <option v-for="option in monthOptions" :value="option" :key="option">{{option}}</option>
            </select>
          </div>
        </div>

        <div class="column is-4 input-right">
          <div class="select" :class="{'is-error': error}">
            <select v-model="day" @change="dayUpdated()">
              <option disabled value="">{{$t('components.DatePicker.day')}}</option>
              <option v-for="option in dayOptions" :value="option" :key="option">{{option}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <p class="help has-text-danger" v-if="error">{{$t(error)}}</p>
  </div>
</template>

<script>
import moment from 'moment';
import Utils from '@/Utils';

function getDaysInMonth(year, month) {
  return Utils.getPaddedNumbers(moment(`${year}-${month}`, 'YYYY-MM').daysInMonth());
}

export default {
  name: 'DatePicker',
  props: [ 'label', 'yearOptions', 'error', 'date' ],
  data: function() {
    const defaultDate = this.date ? moment(this.date, 'YYYY-MM-DD').local() : null,
      year = defaultDate ? defaultDate.year().toString() : '',
      month = defaultDate ? Utils.getPaddedNumber(defaultDate.month() + 1, 2, '0') : '',
      day = defaultDate ? Utils.getPaddedNumber(defaultDate.date(), 2, '0') : '';

    return {
      year,
      month,
      day,

      monthOptions: Utils.getPaddedNumbers(12),
      dayOptions: getDaysInMonth(year, month)
    };
  },
  methods: {
    yearUpdated() {
      this.month = '';
      this.day = '';

      this.monthOptions = Utils.getPaddedNumbers(12);

      this.$emit('changed', '');
    },
    monthUpdated() {
      this.day = '';
      this.dayOptions = getDaysInMonth(this.year, this.month);

      this.$emit('changed', '');
    },
    dayUpdated() {
      this.$emit('changed', moment(`${this.year}-${this.month}-${this.day}`, 'YYYY-MM-DD', true).format('YYYY-MM-DD'));
    }
  }
}
</script>

<style lang="scss" scoped>
.help {
  margin-top: 6px;
}

.date-picker .select, .date-picker select {
  width: 100%;
}

.date-picker .input-left {
  padding-right: 0;
}

.date-picker .input-center {
  padding-left: 6px;
  padding-right: 6px;
}

.date-picker .input-center-bottom-aligned-container {
  margin-top: auto;
}

.date-picker .input-center-bottom-aligned {
  position: relative;
  bottom: 0px;
}

.date-picker .input-right {
  padding-left: 0;
}
</style>
