<script>
import PScoreSpline from '@/assets/pscore-spline.json';

import { Bar } from 'vue-chartjs';
import { Chart } from 'chart.js';

import Settings from '@/Settings';

const spline = PScoreSpline.filter(point => point.x <= Settings.maxPScoreValue);

function getColorFromRisk(risk) {
  switch (risk) {
    case 'low':
      return '#36AD8B';
    case 'intermediate':
      return '#F6CF7C';
    case 'high':
      return '#CE3356';
    default:
      return 'grey';
  }
}

export default {
  name: 'PScoreGraph',
  extends: Bar,
  props: [ 'pscore', 'risk' ],
  mounted () {
    window.onbeforeprint = function() {
      for (var id in Chart.instances) {
        Chart.instances[id].resize();
      }
    };

    this.renderChart({
      labels: spline.map(point => {
        if (point.x % 10 === 0) {
          return point.x;
        }

        return '';
      }),
      datasets: [
        {
          type: 'line',
          data: spline.map(point => {
            if (this.pscore === point.x || (this.pscore >= point.x && point.x >= Settings.maxPScoreValue)) {
              return point.y;
            }

            return null;
          }),
          pointStyle: spline.map(point => {
            if (this.pscore === point.x || this.pscore >= Settings.maxPScoreValue) {
              const pinColor = getColorFromRisk(this.risk),
                customPin = new Image();

              customPin.src = `data:image/svg+xml;base64,${btoa(
`<?xml version="1.0" encoding="UTF-8"?>
<svg width="46px" height="136px" viewBox="0 34 46 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Pin_icon</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Result" transform="translate(-511.000000, -757.000000)">
            <g id="Pin_icon" transform="translate(511.000000, 757.000000)">
                <path d="M23,68 C38.3333333,46.3627686 46,31.328755 46,22.8979592 C46,10.2517655 35.7025492,0 23,0 C10.2974508,0 0,10.2517655 0,22.8979592 C0,31.328755 7.66666667,46.3627686 23,68 Z" id="Oval" fill="#652E6B"></path>
                <circle id="Oval" stroke="#FFFFFF" stroke-width="4" fill="${pinColor}" cx="22.5" cy="22.5" r="14.5"></circle>
            </g>
        </g>
    </g>
</svg>`)}`;
              return customPin;
            }

            return '';
          })
        },
        {
          data: spline.map(point => point.y),
          backgroundColor: spline.map(point => {
            if (point.x < 2.5) {
              return '#36AD8B'; // green
            } else if (point.x >= 2.5 && point.x < 5.5) {
              return '#F6CF7C'; // yellow
            } else if (point.x >= 5.5) {
              return '#CE3356'; // red
            }

            return '';
          })
        }
    ]}, {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 73,
          left: 1
        }
      },
      events: [],
      scales: {
        xAxes: [ {
          scaleLabel: {
            display: false
          },
          gridLines: {
            display: false
          },
          ticks: {
            display: false
          }
        }],
        yAxes: [{
          position: 'right',
          lineWidth: 5,
          gridLines: {
            color: '#b9b9b9',
            zeroLineColor: '#b9b9b9',
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
            drawBorder: false,
            lineWidth: 3,
            zeroLineWidth: 3,
            tickMarkLength: 20
          },
          ticks: {
            stepSize: 20,
            max: 100,
            min: 0,
            padding: 5,
            beginAtZero: true,
            fontFamily: 'Gilroy Bold',
            fontWeight: 400,
            fontSize: 16,
            fontColor: '#652F6C',
            callback: function(value) {
              return `${value}%`;
            }
          }
        }]
      },
      defaultFontFamily: 'Gilroy',
      defaultFontSize: 16,
      legend: {
        display: false
      }
    });
  }
}
</script>