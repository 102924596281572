<template>
  <div>
    <h1 class="title">{{$t('components.TOU.title')}}</h1>
    <h2 class="subtitle is-italic mt-3" v-if="user.me.isPreviousTouAccepted">{{$t('components.TOU.updated')}}</h2>

    <div class="block">
      <div class="content is-normal">
        <ul>
          <li><a :href="getTOUFile()" target="_blank">{{$t('components.TOU.TOUFileDescription')}}</a></li>
          <li><a :href="getIFUFile()" target="_blank">{{$t('components.TOU.IFUFileDescription')}}</a></li>
        </ul>
      </div>
    </div>

      <div class="block">
        <div class="field pt-3 pb-3">
          <div class="control">
            <label class="control-checkbox checkbox pt-is-not-bold">
              {{$t('components.TOU.isTOUAndIFUAccepted.text')}}
              <input type="checkbox" v-model="isTOUAndIFUAccepted" true-value="true" false-value="false">
              <div class="checkbox_indicator"></div>
            </label>
          </div>

          <p class="help has-text-danger" v-if="isTOUAndIFUAcceptedError">{{$t('components.TOU.isTOUAndIFUAccepted.error')}}</p>
        </div>
      </div>

      <div class="block">
        <div class="field pt-3 pb-3">
          <div class="control">
            <label class="control-checkbox checkbox pt-is-not-bold">
              {{$t('components.TOU.isTrained.text')}}
              <input type="checkbox" v-model="isTrained" true-value="true" false-value="false">
              <div class="checkbox_indicator"></div>
            </label>
          </div>

          <p class="help has-text-danger" v-if="isTrainedError">{{$t('components.TOU.isTrained.error')}}</p>
        </div>
      </div>

      <div class="block">
        <div class="field">
          <div class="control">
            <LoadingButton class="button is-primary"
              @click="accept()"
              :text="$t('common.accept')"
              :disabled="isAcceptingTou"
              :isLoading="isAcceptingTou" />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import { pick } from 'lodash';

import { mapState } from 'vuex';

import Settings from '@/Settings';
import LoadingButton from '@/components/shared/LoadingButton.vue';

export default {
  name: 'TOU',
  components: {
    LoadingButton
  },
  data: function() {
    return {
      isTOUAndIFUAccepted: false,
      isTOUAndIFUAcceptedError: false,

      isTrained: false,
      isTrainedError: false,

      isAcceptingTou: false
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  methods: {
    async accept() {
      this.isTOUAndIFUAcceptedError = false;
      this.isTrainedError = false;

      if (this.isTOUAndIFUAccepted !== 'true') {
        this.isTOUAndIFUAcceptedError = true;
      }

      if (this.isTrained !== 'true') {
        this.isTrainedError = true;
      }

      if (this.isTrainedError || this.isTOUAndIFUAcceptedError) {
        return;
      }

      try {
        this.isAcceptingTou = true;

        await axios.post(`${Settings.apiUrl}/tou`, {
          version: this.user.me.touVersion || '2',
          systemVersion: this.user.version
        });

        const response = await axios.get(`${Settings.apiUrl}/me`);

        this.$store.commit('user/setMe', pick(response.data, Settings.USER_FIELDS));

        this.$router.push({ path: decodeURIComponent(this.$route.query.redirectTo) });
      } catch (error) {
        console.error(error);
      } finally {
        this.isAcceptingTou = false;
      }
    },
    getTOUFile() {
      return Settings.getTOUFile((this.user.me.touVersion || '2'), this.user.lang);
    },
    getIFUFile() {
      return Settings.getIFUFile(this.user.lang);
    }
  }
}
</script>
