<template>
  <div class="block">
    <div v-if="isCalculating">
      <PScoreLoading />
    </div>
    <div v-else>
      <div class="block">
        <h1 class="title">{{$t('components.PScoreCalculator.title')}}</h1>
      </div>
      
      <div class="block">
        <p>{{$t('components.PScoreCalculator.description')}}</p>
      </div>

      <div class="block">
        <div class="block">
          <DatePicker class="pscore-calculator-date-container"
            :label="$t('components.PScoreCalculator.dateOfBirth.title')"
            :date="dateOfBirth"
            :yearOptions="yearOfBirthOptions"
            :error="dateOfBirthError"
            v-on:changed="dateOfBirthChanged"/>
        </div>

        <div class="block">
          <div class="field">
            <label class="label">{{$t('components.PScoreCalculator.gleasonScore.title')}}</label>
            
            <div class="control pscore-calculator-container">
              <div class="field columns is-mobile is-1">
                <div class="column is-5 input-left">
                  <div class="select" :class="{'is-error': gleasonScoreError}">
                    <select v-model="gleasonScorePrimary">
                      <option disabled value="">{{$t('components.PScoreCalculator.gleasonScore.placeholderPrimary')}}</option>
                      <option v-for="option in gleasonOptions" :value="option" :key="option">{{option}}</option>
                    </select>
                  </div>
                </div>
                
                <div class="column is-1 input-center has-text-centered">
                  <label class="label pscore-calculator-dot">+</label>
                </div>
                
                <div class="column is-5 input-right">
                  <div class="select" :class="{'is-error': gleasonScoreError}">
                    <select v-model="gleasonScoreSecondary">
                      <option disabled value="">{{$t('components.PScoreCalculator.gleasonScore.placeholderSecondary')}}</option>
                      <option v-for="option in gleasonOptions" :value="option" :key="option">{{option}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <p class="help has-text-danger" v-if="gleasonScoreError">{{$t('components.PScoreCalculator.gleasonScore.error')}}</p>
          </div>
        </div>

        <div class="block">
          <div class="field">
            <label class="label">{{$t('components.PScoreCalculator.TStage.title')}}</label>

            <div class="control pscore-calculator-container">
              <div class="field columns is-mobile is-1">
                <div class="column is-11">
                  <div class="select" :class="{'is-error': TStageError}">
                    <select v-model="TStage">
                      <option disabled value="">{{$t('components.PScoreCalculator.TStage.placeholder')}}</option>
                      <option v-for="option in TStageOptions" :value="option.value" :key="option.value">{{option.text}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <p class="help has-text-danger" v-if="TStageError">{{$t('components.PScoreCalculator.TStage.error')}}</p>
          </div>
        </div>

        <div class="block">
          <div class="field">
            <label class="label">{{$t('components.PScoreCalculator.PSA.title')}}</label>

            <div class="control pscore-calculator-container">
              <div class="field columns is-mobile is-1">
                <div class="control column is-5 input-left">
                  <input type="text" class="input" :class="{'is-error': PSAError}" v-model="PSAFirst" placeholder="00" maxlength="3">
                </div>

                <div class="control column is-1 input-center input-center-bottom-aligned-container has-text-centered">
                  <div class="input-center-bottom-aligned">
                    <label class="label pscore-calculator-dot">.</label>
                  </div>
                </div>

                <div class="control column input-right is-5">
                  <input type="text" class="input" :class="{'is-error': PSAError}" v-model="PSASecond" placeholder="00" maxlength="2">
                </div>
              </div>
            </div>

            <p class="help has-text-danger" v-if="PSAError">{{$t('components.PScoreCalculator.PSA.error')}}</p>
          </div>
        </div>

        <div class="block">
          <h3 class="title">{{$t('components.PScoreCalculator.RNA.title')}}</h3>

          <div class="field">
            <label class="label">deltaCpIGFBP3</label>

            <div class="control pscore-calculator-container">
              <div class="field columns is-mobile is-1">
                <div class="control column input-left is-5">
                  <input type="text" class="input" :class="{'is-error': deltaCpIGFBP3Error}" v-model="deltaCpIGFBP3First" placeholder="0" maxlength="3">
                </div>

                <div class="control column is-1 input-center input-center-bottom-aligned-container has-text-centered">
                  <div class="input-center-bottom-aligned">
                    <label class="label pscore-calculator-dot">.</label>
                  </div>
                </div>

                <div class="control column input-right is-5">
                  <input type="text" class="input" :class="{'is-error': deltaCpIGFBP3Error}" v-model="deltaCpIGFBP3Second" placeholder="00" maxlength="2">
                </div>
              </div>
            </div>

            <p class="help has-text-danger" v-if="deltaCpIGFBP3Error">{{$t('components.PScoreCalculator.RNA.error')}}</p>
          </div>

          <div class="field">
            <label class="label">deltaCpF3</label>

            <div class="control pscore-calculator-container">
              <div class="field columns is-mobile is-1">
                <div class="control column input-left is-5">
                  <input type="text" class="input" :class="{'is-error': deltaCpF3Error}" v-model="deltaCpF3First" placeholder="0" maxlength="3">
                </div>

                <div class="control column is-1 input-center input-center-bottom-aligned-container has-text-centered">
                  <div class="input-center-bottom-aligned">
                    <label class="label pscore-calculator-dot">.</label>
                  </div>
                </div>

                <div class="control column input-right is-5">
                  <input type="text" class="input" :class="{'is-error': deltaCpF3Error}" v-model="deltaCpF3Second" placeholder="00" maxlength="2">
                </div>
              </div>
            </div>

            <p class="help has-text-danger" v-if="deltaCpF3Error">{{$t('components.PScoreCalculator.RNA.error')}}</p>
          </div>

          <div class="field">
            <label class="label">deltaCpVGLL3</label>

            <div class="control pscore-calculator-container">
              <div class="field columns is-mobile is-1">
                <div class="control column input-left is-5">
                  <input type="text" class="input" :class="{'is-error': deltaCpVGLL3Error}" v-model="deltaCpVGLL3First" placeholder="0" maxlength="3">
                </div>

                <div class="control column is-1 input-center input-center-bottom-aligned-container has-text-centered">
                  <div class="input-center-bottom-aligned">
                    <label class="label pscore-calculator-dot">.</label>
                  </div>
                </div>

                <div class="control column input-right is-5">
                  <input type="text" class="input" :class="{'is-error': deltaCpVGLL3Error}" min="0" max="99" v-model="deltaCpVGLL3Second" placeholder="00" maxlength="2">
                </div>
              </div>
            </div>

            <p class="help has-text-danger" v-if="deltaCpVGLL3Error">{{$t('components.PScoreCalculator.RNA.error')}}</p>
          </div>

          <div class="field">
            <label class="label">{{$t('components.PScoreCalculator.kitLotId.title')}}</label>

            <div class="control pscore-calculator-kit-lot-id-container">
              <div class="field columns is-vcentered is-1">
                <div class="control column is-11 is-right">
                  <input
                    type="text"
                    class="input"
                    :class="{'is-error': kitLotIdRequiredError || kitLotIdLengthError}"
                    maxlength="12"
                    v-model="kitLotId"
                  >
                </div>
              </div>           
            </div>
            <p class="help has-text-danger" v-if="kitLotIdRequiredError">{{$t('components.PScoreCalculator.kitLotId.requiredError')}}</p>
            <p class="help has-text-danger" v-else-if="kitLotIdLengthError">{{$t('components.PScoreCalculator.kitLotId.lengthError')}}</p>
          </div>
        </div>

        <div>
          <div class="block">
            <div class="field pt-3 pb-3">
              <div class="control">
                <label class="control-checkbox checkbox pt-is-not-bold">
                  {{$t('components.PScoreCalculator.consent.title')}}
                  <input type="checkbox" v-model="consent" true-value="true" false-value="false">
                  <div class="checkbox_indicator"></div>
                </label>
              </div>

              <p class="help has-text-danger" v-if="consentError">{{$t('components.PScoreCalculator.consent.error')}}</p>
            </div>
          </div>

          <div class="block">
            <div class="field pt-3 pb-3">
              <div class="control">
                <label class="control-checkbox checkbox pt-is-not-bold">
                  {{$t('components.PScoreCalculator.verification.title')}}
                  <input type="checkbox" v-model="verification" true-value="true" false-value="false">
                  <div class="checkbox_indicator"></div>
                </label>
              </div>

              <p class="help has-text-danger" v-if="verificationError">{{$t('components.PScoreCalculator.verification.error')}}</p>
            </div>
          </div>

          <div class="block">
            <div class="field">
              <div class="control">
                <button class="button is-primary" @click="calculate()">{{$t('components.PScoreCalculator.calculate')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProstatypeShortDescription />

      <div class="block">
        <Version />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { isNumber } from 'lodash';

import Settings from '@/Settings';
import Utils from '@/Utils';
import Version from '@/components/shared/Version.vue';
import DatePicker from '@/components/shared/DatePicker.vue';
import PScoreLoading from '@/components/PScoreCalculator/PScoreLoading.vue';
import ProstatypeShortDescription from '@/components/shared/ProstatypeShortDescription.vue';

export default {
  name: 'PScoreCalculator',
  components: {
    ProstatypeShortDescription,
    Version,
    DatePicker,
    PScoreLoading,
  },
  data: function() {
    const currentYear = moment().local().year();

    return {
      isCalculating: false,

      yearOfBirthOptions: Utils.getArrayOfNumbers(100, i => currentYear - i + 1),
      gleasonOptions: [ 3, 4, 5 ],
      TStageOptions: [ '1a', '1b', '1c', '2a', '2b', '2c', '3a', '3b', '3c', '4a', '4b' ].map(tstage => ({ value: tstage, text: `T${tstage}`})),

      dateOfBirth: '',

      gleasonScorePrimary: '',
      gleasonScoreSecondary: '',
      TStage: '',

      PSAFirst: '',
      PSASecond: '',

      deltaCpIGFBP3First: '',
      deltaCpIGFBP3Second: '',

      deltaCpF3First: '',
      deltaCpF3Second: '',

      deltaCpVGLL3First: '',
      deltaCpVGLL3Second: '',

      kitLotId: '',

      consent: '',
      verification: '',

      dateOfBirthError: '',
      gleasonScoreError: false,
      TStageError: false,
      PSAError: false,
      deltaCpIGFBP3Error: false,
      deltaCpF3Error: false,
      deltaCpVGLL3Error: false,
      kitLotIdRequiredError: false,
      kitLotIdLengthError: false,
      consentError: false,
      verificationError: false,
    }
  },
  async created () {
    // running any API request to fetch the latest version
    await axios.get(`${Settings.apiUrl}/me`);
  },
  methods: {
    dateOfBirthChanged(date) {
      this.dateOfBirth = date;
    },
    calculate: async function () {
      try {
        this.resetErrors();

        const input = {
          dateOfBirth: this.dateOfBirth,
          gleasonScorePrimary: this.gleasonScorePrimary,
          gleasonScoreSecondary: this.gleasonScoreSecondary,
          TStage: this.TStage.toLowerCase(),

          PSA: this.getFixedNumber(this.PSAFirst, this.PSASecond),
          deltaCpIGFBP3: this.getFixedNumber(this.deltaCpIGFBP3First, this.deltaCpIGFBP3Second),
          deltaCpF3: this.getFixedNumber(this.deltaCpF3First, this.deltaCpF3Second),
          deltaCpVGLL3: this.getFixedNumber(this.deltaCpVGLL3First, this.deltaCpVGLL3Second),

          kitLotId: this.kitLotId.toLowerCase(),

          consent: this.consent === 'true' ? true : false,
          verification: this.verification === 'true' ? true : false
        };

        this.isCalculating = true;

        const validationResult = await this.validate(input);
        if (!validationResult) {
          throw new Error('Inputs validation is invalid');
        }

        const response = await axios.post(`${Settings.apiUrl}/pscores`, input);

        this.$router.push(`/pscores/${response.data.id}`);
      } catch (error) {

        console.error(error);
        this.flash(this.$t('components.PScoreCalculator.cannotCalculate'), 'error');

      } finally {
        this.isCalculating = false;
      }
    },
    resetErrors() {
      this.dateOfBirthError = '';
      this.gleasonScoreError = false;
      this.TStageError = false;
      this.PSAError = false;
      this.deltaCpIGFBP3Error = false;
      this.deltaCpF3Error = false;
      this.deltaCpVGLL3Error = false;
      this.kitLotIdRequiredError = false;
      this.kitLotIdLengthError = false;
      this.consentError = false;
      this.verificationError = false;
    },
    getFixedNumber: function (first, second) {
      if (!first || !second) {
        return null;
      }

      return new Number(new Number(`${first.toString()}.${(second||0).toString()}`).toFixed(2));
    },
    async validate(input) {
      this.resetErrors();

      let result = true;
      if (!moment(input.dateOfBirth, 'YYYY-MM-DD').isValid()) {
        this.dateOfBirthError = 'components.PScoreCalculator.dateOfBirth.error';
        result = false;
      }

      if (!isNumber(input.gleasonScorePrimary) || input.gleasonScorePrimary < 3 || input.gleasonScorePrimary > 5
        || !isNumber(input.gleasonScoreSecondary) || input.gleasonScoreSecondary < 3 || input.gleasonScoreSecondary > 5) {
        this.gleasonScoreError = true;
        result = false;
      }

      if (!input.TStage) {
        this.TStageError = true;
        result = false;
      }

      if (!Utils.validateNumberValue(input.PSA, 0, 999)) {
        this.PSAError = true;
        result = false;
      }

      if (!Utils.validateNumberValue(input.deltaCpIGFBP3, -10, 20)) {
        this.deltaCpIGFBP3Error = true;
        result = false;
      }

      if (!Utils.validateNumberValue(input.deltaCpF3, -10, 20)) {
        this.deltaCpF3Error = true;
        result = false;
      }

      if (!Utils.validateNumberValue(input.deltaCpVGLL3, -10, 20)) {
        this.deltaCpVGLL3Error = true;
        result = false;
      }

      if (!this.kitLotId) {
        this.kitLotIdRequiredError = true;
        result = false;
      } else if (this.kitLotId.length < 6) {
        this.kitLotIdLengthError = true;
        result = false;
      }

      if (input.consent !== true ) {
        this.consentError = true;
        result = false;
      } 

      if (input.verification !== true ) {
        this.verificationError = true;
        result = false;
      } 

      return result;
    }
  },
  watch: {
    isCalculating: function () {
      this.$emit('is-calculating-changed', this.isCalculating);
    }    
  }
}
</script>

<style lang="scss" scoped>
.help {
  margin-top: 6px;
}

.label.pscore-calculator-dot {
  line-height: 38px;
}

h3 {
  margin-bottom: 0 !important;
}

.pscore-calculator-container {
  max-width: 260px;
}

.pscore-calculator-kit-lot-id-container {
  max-width: 310px;
}

.pscore-calculator-date-container {
  max-width: 358px;
}

.pscore-calculator-container .select, .pscore-calculator-container select {
  width: 100%;
}

.pscore-calculator-container .input-left {
  padding-right: 0;
}

.pscore-calculator-container .input-center {
  padding-left: 6px;
  padding-right: 6px;
}

.pscore-calculator-container .input-center-bottom-aligned-container {
  margin-top: auto;
}

.pscore-calculator-container .input-center-bottom-aligned {
  position: relative;
  bottom: 0px;
}

.pscore-calculator-container .input-right {
  padding-left: 0;
}

@media screen and (max-width: 374px) { 
  .button {
    min-width: 100%;
    font-size: .8em;
  }
}
</style>
