<template>
  <div class="block">
    <div class="columns is-mobile">
      <div class="column is-half">
        <label class="label">{{$t('components.PScoreResult.date')}}: {{getFormattedDate(score.metadata.createdAt)}}</label>
      </div>

      <div class="column is-half">
        <label class="label">{{$t('components.PScoreResult.age')}}: {{getAgeFromDateOfBirth(score.metadata.createdAt, score.input.dateOfBirth)}}</label>
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column is-half" style="padding-top: 0;">
        <label class="label">{{$t('components.PScoreResult.reportId')}}: {{score.id}}</label>
      </div>

      <div class="column is-half" style="padding-top: 0;">
        <label class="label">{{$t('components.PScoreResult.kitLotId')}}: {{score.input.kitLotId.toUpperCase()}}</label>
      </div>
    </div>

    <hr>
    
    <div class="columns is-mobile no-bottom-margin">
      <div class="column is-half no-bottom-padding">
        <div class="columns is-mobile">
          <div class="column is-two-thirds-mobile is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <label class="label">{{$t('components.PScoreCalculator.gleasonScore.title')}}</label>
          </div>
          <div class="column is-one-third-mobile is-two-thirds-desktop is-two-thirds-widescreen is-two-thirds-fullhd has-text-centered">
            <label class="label">{{score.input.gleasonScorePrimary}}+{{score.input.gleasonScoreSecondary}}</label>
          </div>
        </div>
      </div>

      <div class="column is-half no-bottom-padding">
        <div class="columns is-mobile">
          <div class="column is-two-thirds-mobile is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <label class="label">deltaCpIGFBP3</label>
          </div>
          <div class="column is-one-third-mobile is-two-thirds-desktop is-two-thirds-widescreen is-two-thirds-fullhd has-text-centered">
            <label class="label">{{score.input.deltaCpIGFBP3.toFixed(2)}}</label>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="columns is-mobile no-bottom-margin">
      <div class="column is-half no-bottom-padding">
        <div class="columns is-mobile">
          <div class="column is-two-thirds-mobile is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <label class="label">{{$t('components.PScoreCalculator.TStage.title')}}</label>
          </div>
          <div class="column is-one-third-mobile is-two-thirds-desktop is-two-thirds-widescreen is-two-thirds-fullhd has-text-centered">
            <label class="label">T{{score.input.TStage}}</label>
          </div>
        </div>
      </div>
      <div class="column is-half no-bottom-padding">
        <div class="columns is-mobile">
          <div class="column is-two-thirds-mobile is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <label class="label">deltaCpF3</label>
          </div>
          <div class="column is-one-third-mobile is-two-thirds-desktop is-two-thirds-widescreen is-two-thirds-fullhd has-text-centered">
            <label class="label">{{score.input.deltaCpF3.toFixed(2)}}</label>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="columns is-mobile no-bottom-margin">
      <div class="column is-half no-bottom-padding">
        <div class="columns is-mobile">
          <div class="column is-two-thirds-mobile is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <label class="label">{{$t('components.PScoreCalculator.PSA.title')}}</label>
          </div>
          <div class="column is-one-third-mobile is-two-thirds-desktop is-two-thirds-widescreen is-two-thirds-fullhd has-text-centered">
            <label class="label">{{score.input.PSA}}</label>
          </div>
        </div>
      </div>
      <div class="column is-half no-bottom-padding">
        <div class="columns is-mobile">
          <div class="column is-two-thirds-mobile is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <label class="label">deltaCpVGLL3</label>
          </div>
          <div class="column is-one-third-mobile is-two-thirds-desktop is-two-thirds-widescreen is-two-thirds-fullhd has-text-centered">
            <label class="label">{{score.input.deltaCpVGLL3.toFixed(2)}}</label>
          </div>
        </div>
      </div>
    </div>

    <hr>
  </div>
</template>

<script>
import moment from 'moment';

import Utils from '@/Utils';

export default {
  name: 'PScoreInputDisplay',
  props: [ 'score' ],
  methods: {
    getAgeFromDateOfBirth(reportCreatedAt, dateOfBirth) {
      return Utils.getAgeFromDateOfBirth(reportCreatedAt, dateOfBirth);
    },
    getFormattedDate: date => {
      return moment(date).format('YYYY-MM-DD');
    }
  }
}
</script>

<style lang="scss" scoped>
  .column {
    white-space: nowrap;
  }
  
  .no-bottom-margin {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .no-bottom-padding {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @media screen and (max-width: 480px) { 
    .label {
      font-size: .8em;
    }
  }

  @media screen and (max-width: 374px) { 
    .label {
      font-size: .72em;
    }
  }
</style>