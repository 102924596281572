<template>
  <div class="block">
    <TableView 
      :title="$t('components.PScores.title')" 
      :emptyText="$t('components.PScores.empty')"
      uri="/pscores"
      toName="pscoresId"
      limit="5">
      <template v-slot:item="{item}">
        <PScoreRow :score="item" />
      </template>
    </TableView>
  </div>
</template>

<script>
import TableView from '@/components/shared/TableView.vue';
import PScoreRow from '@/components/PScores/PScoreRow.vue';

export default {
  name: 'PScores',
  components: {
    TableView,
    PScoreRow
  }
};
</script>
