<template>
  <div>
    <UserInfo />
    <Navigation />

    <div class="main-content pt-5">
      <div class="block">
        <h1 class="title">{{$t('views.help.title')}}</h1>
      </div>

      <div class="block">
        <div class="content is-normal">
          <ul>
            <li v-for="langugage in $t('views.help.supportedLanguages')" :key="langugage.iso">
              <a :href="getIFUFile(langugage.iso)" target="_blank">PWS IFU - {{langugage.name}}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="block">
        <Version />
      </div>
    </div>
  </div>
</template>

<script>
import Settings from '@/Settings';

import Navigation from '@/components/shared/Navigation.vue';
import UserInfo from '@/components/shared/UserInfo.vue';
import Version from '@/components/shared/Version.vue';

export default {
  name: 'HelpView',
  components: {
    Navigation,
    UserInfo,
    Version
  },
  methods: {
    getIFUFile(lang) {
      return Settings.getIFUFile(lang);
    }
  }
}
</script>
