<template>
  <div>
    <Loading v-if="isLoading" />

    <div v-else-if="!error">
      <label class="label">{{title}}</label>

      <div v-if="data.items.length>0">
        <template v-for="item in data.items">
          <router-link :key="item.id" :to="{ name: toName, params: { id: item.id }}">
            <div class="border-01 has-text-centered table-view-row">
              <slot name="item" v-bind:item="item"></slot>
            </div>
          </router-link>
        </template>
      </div>
      <div v-else>
        <p class="block">{{emptyText}}</p>
      </div>
      <div v-if="!isShowMoreHidden && data.items.length>0">
        <a href="javascript:" @click.prevent="loadMore()">        
          <FontAwesomeIcon :icon="['fa', 'angle-down' ]" />
          <LoadingLink
            style="margin-left: 5px;"
            :text="$t('components.TableView.showMore')"
            :isLoading="isLoadingMore" />
        </a>
      </div>
    </div>
    <div v-else>
      <SomethingWentWrong />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { concat } from 'lodash';

import Settings from '@/Settings';
import Loading from '@/components/shared/Loading.vue';
import LoadingLink from '@/components/shared/LoadingLink.vue';
import SomethingWentWrong from '@/components/shared/SomethingWentWrong.vue';

function getResetData() {
  return {
    items: []
  };
}

export default {
  name: 'TableView',
  props: [ 'title', 'emptyText', 'uri', 'limit', 'toName' ],
  components: {
    Loading,
    LoadingLink,
    SomethingWentWrong
  },
  data () {
    return {
      isLoading: false,
      isLoadingMore: false,
      isShowMoreHidden: false,
      data: getResetData(),
      error: false,
      skip: 0
    };
  },
  created () {
    this.fetchData();
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    async fetchData () {
      try {
        this.error = false;
        this.data = getResetData();
        this.isLoading = true;

        this.data = (await axios.get(`${Settings.apiUrl}/${this.uri}?skip=${this.skip}&limit=${this.limit}`)).data;

        this.isShowMoreHidden = !(this.data.totalCount > this.data.items.length);
      } catch (error) {
        console.error(error);
        this.error = true;
      } finally {
        this.isLoading = false;
      }
    },
    async loadMore() {
      if (this.isLoadingMore) {
        return;
      }

      try {        
        this.isLoadingMore = true;

        const additionalData = (await axios.get(`${Settings.apiUrl}${this.uri}?skip=${this.data.items.length}&limit=${this.limit}`)).data;

        if (additionalData.items.length <= 0) {
          this.isShowMoreHidden = true;
        } else {
          if (additionalData.items.length < this.limit || this.data.items.length + additionalData.items.length >= additionalData.totalCount) {
            this.isShowMoreHidden = true;
          }

          this.data.totalCount = this.data.items.length;
          this.data.items = concat(this.data.items, additionalData.items);
        }
      } catch (error) {
        console.error(error);
        this.flash(this.$t('common.error'), 'error');
      } finally {
        this.isLoadingMore = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .table-view-row {
    background-color: #FFF;
    margin-bottom: 12px;
  }
</style>
