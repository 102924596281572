<script>
import moment from 'moment';
import { isNumber, padStart } from 'lodash';

export default {
  getReadableUTCDate(date) {
    return `${moment(date).utc().format('YYYY-MM-DD HH:mm:ss')} UTC`;
  },
  getFormattedDate(date) {
    return moment(date).local().format('YYYY-MM-DD');
  },
  getFormattedDateFull(date) {
    return moment(date).local().format('YYYY-MM-DD HH:mm');
  },
  getAgeFromDateOfBirth(reportCreatedAt, dateOfBirth) {
    return moment(reportCreatedAt).diff(moment(dateOfBirth, 'YYYY-MM-DD'), 'years');
  },
  getArrayOfNumbers(length, predicate) {
    return Array.from({ length }, (_, i) => predicate(i +  1));
  },
  getPaddedNumber(value, count, char) {
    return padStart(value, count, char);
  },
  getPaddedNumbers(length) {
    return this.getArrayOfNumbers(length, i => (this.getPaddedNumber(i.toString(), 2, '0')));
  },
  validateNumberValue(value, min, max) {
    if (isNaN(value) || !isNumber(value) || value < min || value > max) {
      return false;
    }

    return true;
  },
  b64toBlob(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data),
        byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  },
  downloadFile(fileName, base64data) {
    // https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
    const blob = this.b64toBlob(base64data, 'application/pdf');

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    const data = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.click();
    setTimeout(function(){
      window.URL.revokeObjectURL(data);
    }, 100);
  }
}
</script>
