<template>
  <div class="columns is-vcentered table-view-column is-mobile">
    <div class="column has-text-left">
      <label class="label" style="width: 95px; margin-left: 1em;">{{getFormattedDate(score.metadata.createdAt)}}</label>
    </div>

    <div class="column has-text-left">
      <label class="label" style="width: 95px;">
        <span>ID:</span>
        <span style="margin-left: 5px;">{{score.id}}</span>
      </label>
    </div>

    <div class="column is-narrow has-text-right is-hidden-mobile">
      <div style="width: 30px;">
        <FontAwesomeIcon :icon="['fa', 'circle' ]" :class="getColorClass(score.result.PScoreRisk)"/>
      </div>
    </div>

    <div class="column has-text-centered is-hidden-tablet">
      <div class="has-text-centered">
        <FontAwesomeIcon :icon="['fa', 'circle' ]" :class="getColorClass(score.result.PScoreRisk)"/>
      </div>
    </div>

    <div class="column has-text-left is-hidden-mobile" style="padding-left: 0;">
      <label class="label" style="width: 120px;">{{$t(`components.PScoreDiagram.${score.result.PScoreRisk}`)}}</label>
    </div>

    <div class="column is-narrow">
      <div style="width: 30px;">
        <FontAwesomeIcon :icon="['fa', 'chevron-right' ]" style="width: 30px;" size="lg" class="color-03" />
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from 'lodash';
import Utils from '@/Utils';

export default {
  name: 'PScoreRow',
  props: [ 'score' ],
  methods: {
    getFormattedDate(date) {
      return Utils.getFormattedDate(date);
    },
    capitalize(text) {
      return capitalize(text);
    },
    getColorClass(risk) {
      switch (risk) {
        case 'low':
          return 'color-04';
        case 'intermediate':
          return 'color-04-1';
        case 'high':
          return 'color-05';
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  label {
    cursor: pointer;
  }

  .table-view-column {
    min-height: 60px;
  }

  .columns:last-child {
    margin-bottom: 0;
  }

  .columns {
    margin-top: 0;
  }
  
  .column {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media screen and (max-width: 374px) { 
    .label {
      width: 100% !important;
      font-size: .68em !important;
    }
  }

  @media screen and (max-width: 330px) { 
    .label {
      font-size: .6em !important;
    }
  }
</style>
