<template>
  <div>
    <UserInfo />
    <Navigation />

    <div class="main-content pt-5">
      <TOU />
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/shared/Navigation.vue';
import UserInfo from '@/components/shared/UserInfo.vue';
import TOU from '@/components/shared/TOU.vue';

export default {
  name: 'TOUView',
  components: {
    Navigation,
    UserInfo,
    TOU
  }
}
</script>
