<template>
  <div v-if="this.user && this.user.version">
    <div class="level mb-3 mt-6">
      <div class="level-left">
        <div class="level-item">
          <figure class="image is-48x48">
            <img src="/img/ce-mark48@2x.png" width="48" height="auto">
          </figure>
        </div>
      </div>
    </div>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <p>Prostatype Genomics AB, PWS ver. {{this.user.version}}</p>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <a :href="getTOUFile()" target="_blank">{{$t('components.Version.termsOfUse')}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Settings from '@/Settings';

export default {
  name: 'Version',
  computed: {
    ...mapState([
      'user'
    ])
  },
  methods: {
    getTOUFile() {
      return Settings.getTOUFile((this.user.me.touVersion || '2'), this.user.lang);
    }
  }
}
</script>
