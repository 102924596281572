<template>
  <div class="logo-container">
    <StageBadge />
    <img src="/img/logo-prostatype@2x.png" width="228" height="auto" alt="Prostatype">
  </div>
</template>

<script>
import StageBadge from '@/components/shared/StageBadge.vue';

export default {
  name: 'Logo',
  components: {
    StageBadge
  }
}
</script>

<style lang="scss" scoped>
.logo-container {
  display: inline-block;
  font-size: 0; // This is a workaround, so inline-block is not adding extra line-height
}

.logo-container img {
  max-height: 69px;
}
</style>