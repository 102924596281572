import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';

import messages from '@/locales/en.json';

export const DEFAULT_LANGUAGE_LANG = 'en';

export const AVAILABLE_LANGUAGES = [
  { langCode: 'en', title: 'English', flagCode: 'gb' },
  { langCode: 'fr', title: 'French', flagCode: 'fr' },
  { langCode: 'de', title: 'German', flagCode: 'de' },
  { langCode: 'it', title: 'Italian', flagCode: 'it' },
  { langCode: 'pt', title: 'Portuguese', flagCode: 'pt' },
  { langCode: 'es', title: 'Spanish', flagCode: 'es' },
  { langCode: 'sv', title: 'Swedish', flagCode: 'se' }
];

Vue.use(VueI18n);

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

const loadedLanguages = [DEFAULT_LANGUAGE_LANG];

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || DEFAULT_LANGUAGE_LANG,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || DEFAULT_LANGUAGE_LANG,
  messages
});

export async function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  function setCurrentLanguage(lang) {
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  }

  try {
    const message = await import(/* webpackChunkName: 'lang-[request]' */ `./locales/${lang}.json`);
    i18n.setLocaleMessage(lang, message.default[lang]);

    setCurrentLanguage(lang);
  } catch (err) {
    console.error(err.message, `${lang.toUpperCase()} language has not changed`);

    setCurrentLanguage(DEFAULT_LANGUAGE_LANG);
  }
}

export default i18n;
