import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import TOUView from '../views/TOUView.vue';
import HelpView from '../views/HelpView.vue';
import PScoresView from '../views/PScoresView.vue';
import PScoreIdView from '../views/PScoreIdView.vue';
import PScoreIdPrintedView from '../views/printed/PScoreIdPrintedView.vue';

import store from '@/store';

import { loadLanguageAsync, DEFAULT_LANGUAGE_LANG, AVAILABLE_LANGUAGES } from '@/i18n';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'login' },
    props: route => ({ lang: route.query.lang }),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/login',
    name: 'login',
    props: route => ({ lang: route.query.lang }),
    component: LoginView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/tou',
    name: 'tou',
    props: route => ({ lang: route.query.lang }),
    component: TOUView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/help',
    name: 'help',
    props: route => ({ lang: route.query.lang }),
    component: HelpView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/home',
    name: 'home',
    props: route => ({ lang: route.query.lang }),
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pscores',
    name: 'pscores',
    props: route => ({ lang: route.query.lang }),
    component: PScoresView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pscores/:id',
    name: 'pscoresId',
    props: route => ({ lang: route.query.lang }),
    component: PScoreIdView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: [ 
      '/pscores/:id/printed/',
      '92250bb3-0e21-4c67-8138-27b08a1e97fc'
    ].join(''),
    name: 'pscoresIdPrinted',
    props: route => ({ lang: route.query.lang }),
    component: PScoreIdPrintedView
  },
  {
    path: '*', 
    component: () => import('../views/NotFoundView.vue'), 
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

async function nextTranslated(lang, next, params) {
  await loadLanguageAsync(lang).then(() => next(params));
}

router.beforeEach((to, from, next) => {

  const checkedLang = () => {
    const checkedLangCode = AVAILABLE_LANGUAGES.find(
      language => language.langCode === to.query.lang
    );
 
    if (checkedLangCode) {
      store.commit('user/setLanguage', checkedLangCode.langCode);
    }
    
    if(checkedLangCode) {
      return checkedLangCode.langCode;
    } else if (!checkedLangCode && store.state.user !== DEFAULT_LANGUAGE_LANG){
      return store.state.user.lang;
    } else {
      return DEFAULT_LANGUAGE_LANG;
    }
  }

  const lang = checkedLang();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.user && store.state.user.isLoggedIn) {
      if (store.state.user.me.isTouAccepted || to.name.indexOf('tou', 'logout', 'login') >= 0) {
        nextTranslated(lang, next);
      } else {
        nextTranslated(lang, next, {
          name: 'tou',
          query: {
            redirectTo: encodeURIComponent(to.path)
          }
        });
      }
    } else {
      nextTranslated(lang, next, {
        name: 'login',
        query: {
          errorMessage: 'Unauthorized'
        }
      });
    }
  } else {
    nextTranslated(lang, next);
  }
});

export default router;
