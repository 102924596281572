<template>
  <div class="main-content">
    <div style="position: absolute; top: 25px; right: 10px;" v-if="$route.query.lang==='es'">
      <img src="/img/custom/Logo-EurofinsMegalab.png" width="380" height="auto">
    </div>
    <PScoreResultPrinted :scoreId="$route.params.id" />
  </div>
</template>

<script>
import PScoreResultPrinted from '@/components/PScoreResult/PScoreResultPrinted.vue';

export default {
  name: 'PScoreIdPrintedView',
  components: {
    PScoreResultPrinted
  }
}
</script>
