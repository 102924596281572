<template>
  <div class="columns is-vcentered is-mobile" style="height: 200px;">
    <div class="column color-01 has-text-centered" :style="color ? `color: ${color};` : ''">
      <FontAwesomeIcon :icon="['fa', 'spinner']" size="3x" pulse />
      <span class="sr-only">{{$t('components.Loading.title')}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: [ 'color' ]
}
</script>
