<template>
  <div class="block menu-content">
    <div class="modal is-clipped" :class="{'is-active': isBurgerActive}">
      <div class="modal-background"></div>
      <div class="is-flex is-flex-direction-column is-justify-content-center is-align-content-center">

        <div v-if="!isMobileLangMenuOpen">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <div class="navbar-item is-flex is-justify-content-center">
                <router-link to="/home">{{$t('components.Navigation.home')}}</router-link>
              </div>
            </div>
          </div>
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <div class="navbar-item is-flex is-justify-content-center">
                <router-link to="/pscores">{{$t('components.Navigation.pscores')}}</router-link>
              </div>
            </div>
          </div>
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <div class="navbar-item is-flex is-justify-content-center">
                <router-link to="/help">{{$t('components.Navigation.help')}}</router-link>
              </div>
            </div>
          </div>
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <div class="navbar-item is-flex is-justify-content-center">
                <a href="javascript:" @click="logout">{{$t('components.Navigation.logout')}}</a>
              </div>
            </div>
          </div>
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <div class="navbar-item is-flex is-justify-content-center">
                <LanguageSwitcher 
                  :isBurgerActive="isBurgerActive" 
                  :isMobileLangMenuOpen="isMobileLangMenuOpen" 
                  @toggleMobileLangMenu="toggleMobileLangMenu" 
                />
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="is-centered">
            <div class="has-text-centered">
              <div class="navbar-item is-flex is-justify-content-center">
                <LanguageSwitcher
                  :isBurgerActive="isBurgerActive" 
                  :isMobileLangMenuOpen="isMobileLangMenuOpen" 
                  @toggleMobileLangMenu="toggleMobileLangMenu" 
                />
              </div>
            </div>
          </div>
        </div>

      </div>
      <button class="modal-close is-large button-close" aria-label="close" @click="isBurgerActive=!isBurgerActive; isMobileLangMenuOpen=false"></button>
  
    </div>

    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <div class="navbar-item">
          <Logo />
        </div>

        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
          :class="{'is-hidden': isBurgerActive}" @click="isBurgerActive=!isBurgerActive"
          v-if="!isNavigationHidden">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" v-if="!isNavigationHidden">
        <div class="navbar-end">
          <div class="navbar-item">
            <router-link to="/home">{{$t('components.Navigation.home')}}</router-link>
          </div>
          <div class="navbar-item">
            <router-link to="/pscores">{{$t('components.Navigation.pscores')}}</router-link>
          </div>
          <div class="navbar-item">
            <router-link to="/help">{{$t('components.Navigation.help')}}</router-link>
          </div>
          <div class="navbar-item">
            <a href="javascript:" @click="logout">{{$t('components.Navigation.logout')}}</a>
          </div>
            <div class="navbar-item">
              <LanguageSwitcher />
            </div>
        </div>
      </div>
          
    </nav>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import axios from 'axios';

import Logo from '@/components/shared/Logo.vue';
import Settings from '@/Settings';
import LanguageSwitcher from './LanguageSwitcher.vue';

export default {
  name: 'Navigation',
  props: [ 'isNavigationHidden' ],
  components: {
    Logo,
    LanguageSwitcher
  },
  data: function () {
    return {
      isBurgerActive: false,
      isLoggingOut: false,
      isMobileLangMenuOpen: false
    };
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  methods: {
    logout: async function () {
      try {
        this.isLoggingOut = true;
        await axios.post(`${Settings.apiUrl}/logout`, {});
        this.$store.commit('user/reset');
        this.$router.push('/login');
      } catch (error) {
        this.$store.commit('user/reset');
        console.error(error);
      } finally {
        this.isLoggingOut = false;
      }
    },
    toggleMobileLangMenu: function () {
      this.isMobileLangMenuOpen = !this.isMobileLangMenuOpen
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

$primary: $color01;
$secondary: $color02;

$close-button-size: 40px;
$close-button-width: 3px;

.is-large.modal-close, .is-large.delete {
  height: $close-button-size;
  max-height: $close-button-size;
  max-width: $close-button-size;
  min-height: $close-button-size;
  min-width: $close-button-size;
  width: $close-button-size;
}

.button-close {
  border: solid 2px $primary !important;
}

.button-close::before {
  height: $close-button-width;
}

.button-close::after {
  width: $close-button-width;
}

.button-close::before, .button-close::after {
  color: $primary;
  background-color: $primary;
}

.button-close:hover, .button-close:focus {
  background-color: $secondary;
}

.modal-background {
  background-color: rgba(255, 255, 255, 0.86);
}

.navbar {
  background: transparent;
}

.navbar-burger {
  margin-top: 1.25em;
}

.navbar-item a {
  margin-top: 3px;
  letter-spacing: 2px;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 3px solid transparent;
}

.navbar-item, .navbar-link {
  padding-right: 24px;
}

.router-link-active {
  border-bottom: 3px solid !important;
}

@media screen and (max-height: 320px) {
  .columns {
    max-height: 2.5rem !important;
  }
}
</style>
