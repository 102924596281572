<template>
  <div>
    <div class="border-label description-label" :class="getBackgroundClassFromRisk(risk)">
      <div class="columns is-mobile is-vcentered">
        <div class="column is-one-third"><h1 class="title pscore-label pscore-label-large"><span :class="getTextClassFromRisk(risk)">{{pscore}}</span></h1></div>
        <div class="column is-two-thirds">
          <div class="pscore-label">
            <div><label class="label pscore-label-small"><span :class="getTextClassFromRisk(risk)">{{$t('components.PScoreDiagram.pscore')}}: {{pscore}}</span></label></div>
            <div><label class="label pscore-label-small"><span :class="getTextClassFromRisk(risk)">{{$t('components.PScoreDiagram.mortalityRisk')}}: {{$t(`components.PScoreDiagram.${risk}`)}}</span></label></div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-label description-label background-color-02" style="margin-top: 7px;">
      <div class="columns is-mobile is-vcentered">
        <div class="column is-one-third"><h1 class="title pscore-label pscore-label-large"><span class="color-01">{{getMortalityRiskFromPScore(pscore)}}%</span></h1></div>
        <div class="column is-two-thirds">
          <div class="pscore-label"><label class="label pscore-label-small"><span class="color-01">{{$t('components.PScoreDiagram.mortalityRiskDescripition')}}</span></label></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash';

import PScoreSpline from '@/assets/pscore-spline.json';

export default {
  name: 'PScoreDiagramLegend',
  props: [ 'pscore', 'risk' ],
  methods: {
    getMortalityRiskFromPScore: function (pscore) {
      return find(PScoreSpline, { x: pscore }).y;
    },
    getBackgroundClassFromRisk: function (risk) {
      switch (risk) {
        case 'low':
          return 'background-color-04';
        case 'intermediate':
          return 'background-color-04-1';
        case 'high':
          return 'background-color-05';
        default:
          return 'grey';
      }      
    },
    getTextClassFromRisk: function (risk) {
      switch (risk) {
        case 'low':
        case 'high':
          return 'color-white';
        default:
          return 'color-01';
      }      
    }
  }
}
</script>

<style lang="scss" scoped>
h1.title {
  font-size: 28px;
}

.border-label {
  border-radius: 6px;
  border: 2px solid transparent;
}

.pscore-label {
  padding: 0 0 0 20px;
}

.pscore-label-large  {
  white-space: nowrap;
}

.pscore-label-small  {
  font-size: 13px;
}

.description-label {
  width: 325px;
}

@media screen and (max-width: 374px) { 
  .description-label {
    width: 100%;
  }
}
</style>
