<template>
  <div>
    <UserInfo />
    <Navigation />
    <div class="main-content pt-5">
      <PScoreResult :scoreId="$route.params.id" />
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/shared/Navigation.vue';
import UserInfo from '@/components/shared/UserInfo.vue';
import PScoreResult from '@/components/PScoreResult/PScoreResult.vue';

export default {
  name: 'PScoreIdView',
  components: {
    Navigation,
    UserInfo,
    PScoreResult
  }
}
</script>
