<template>
  <div class="columns is-vcentered is-mobile border-01 color-01" style="margin:0; padding:0; height: 150px;">
    <div class="column has-text-centered">
      <div style="margin: 10px;">
        <FontAwesomeIcon :icon="[ 'far', 'frown' ]" size="2x" />
      </div>

      <div>
        <label class="label">
          <span class="color-01">{{this.text||this.$t('common.error')}}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SomethingWentWrong',
  props: [ 'text' ]
}
</script>
